import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import types from '../types';

const tokenExpired = (error, requestAction) => {
  if (requestAction.type.includes('VAULT')) {
    const errorString = get(error, ['response', 'data', 'error'], error);
    if (isFunction(errorString.includes)) {
      return errorString.includes('Invalid or expired session ID.');
    }
  }
}

const onRequest = (request, requestAction, store) => {
  const { login: { data: token } } = store.getState();

  if (token) {
    return {
      ...request,
      headers: {
        ...request.headers,
        'Authorization': token,
      },
    };
  }

  return request;
};

const onError = async (error, requestAction, store) => {
  if (tokenExpired(error, requestAction)) {
    const { login: { username, password, passwordIsGood } } = store.getState();

    const { data } = await store.dispatch({
      type: types.LOGIN,
      request: {
        url: '/api/login',
        method: 'post',
        data: {
          username,
          password
        }
      },
    });

    // we didn't manage to get new token
    if (!data) {
      throw error;
    }

    // we fire the same request again with new token
    try {
      const newResponse = await store.dispatch({
        ...requestAction,
        request: {
          ...requestAction.request,
          headers: {
            ...requestAction.request.headers,
            'Authorization': data
          }
        },
      });

      if (newResponse.data) {
        return { data: newResponse.data };
      }
    } catch (err) {
      throw err;
    }
  }

  // either not token related error or we failed again
  throw error;
}

export default {
  onRequest: onRequest,
  onError: onError
};
