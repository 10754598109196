// @flow

import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import {
	Typeahead,
	Highlighter,
	Menu,
	MenuItem,
} from 'react-bootstrap-typeahead';
import { FixedSizeList as List } from 'react-window';
import downloadFile from 'js-file-download';

const Loading = () => (
	<InputGroup.Append>
		<Button variant='outline-secondary' disabled>
			<Spinner
				as='span'
				animation='border'
				size='sm'
				role='status'
				aria-hidden='true'
			/>
			<span className='sr-only'>Loading...</span>
		</Button>
	</InputGroup.Append>
);

const DeckTypeahead = ({
	onChange,
	onRemove,
	accept,
	decks,
	deckExists,
	selected,
	labelKey,
	showDownload,
	download,
	loading,
	disabled,
	placeholder,
	mode,
	setMode,
}: {
	accept?: string,
	disabled?: string | boolean,
	decks: Array<any>,
	deckExists?: boolean,
	download?: Blob | null,
	loading: boolean,
	selected: string,
	labelKey: string,
	showDownload?: boolean,
	placeholder?: string,
	onChange: Function,
	onRemove?: Function,
	setMode: Function,
	mode?: string,
}) => {
	const renderMenu = useCallback((results, menuProps, props) => {
		const itemHeight = 32;

		return (
			<Menu {...menuProps}>
				<List
					itemData={props.results}
					height={results.length < 5 ? results.length * itemHeight : 300}
					itemCount={results.length}
					itemSize={itemHeight}
				>
					{({ data, index, style }) => {
						return (
							<MenuItem
								key={index}
								position={index}
								style={style}
								option={data[index]}
							>
								<Highlighter search={props.text}>
									{props.labelKey !== 'label'
										? data[index][props.labelKey]
										: data[index]}
								</Highlighter>
							</MenuItem>
						);
					}}
				</List>
			</Menu>
		);
	});

	return (
		<Form.Group as={Col} controlId='vaultId'>
			<Form.Label>Vault ID</Form.Label>
			<InputGroup>
				<Typeahead
					id='deck-typeahead'
					options={decks || []}
					placeholder={placeholder}
					onChange={onChange}
					labelKey={labelKey}
					selected={[selected]}
					disabled={!decks || disabled}
					isValid={!!selected}
					renderMenu={renderMenu}
					paginate={false}
					positionFixed
				/>
				{!decks && <Loading />}
				{loading && <Loading />}
				{mode === 'deploy' && showDownload && (
					<InputGroup.Append>
						<Button
							variant='outline-secondary'
							onClick={() => downloadFile(download, `${selected}${accept}`)}
							disabled={!download}
						>
							Download from Vault
						</Button>
					</InputGroup.Append>
				)}
				{onRemove && (
					<InputGroup.Append>
						<Button
							variant='outline-secondary'
							onClick={() => onRemove(selected)}
						>
							Remove
						</Button>
					</InputGroup.Append>
				)}
			</InputGroup>
			<Form.Control.Feedback type='invalid'>
				Deck is invalid
			</Form.Control.Feedback>
			<Form.Control.Feedback type='valid'>Deck is valid</Form.Control.Feedback>
		</Form.Group>
	);
};

DeckTypeahead.defaultProps = {
	decks: [],
	selected: '',
	loading: false,
	disabled: false,
	showDownload: true,
	placeholder: 'Enter Vault ID',
};

export default DeckTypeahead;
