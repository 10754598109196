import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import get from 'lodash/get';

const OverwriteModal = ({ show, file, uploadVideo, handleClose }) => (
	<Modal show={show}>
		<Modal.Header>
			<Modal.Title>Overwrite {get(file, 'name')}?</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<p>
				A video with the same name already exists on the server. Overwrite
				video?
			</p>
		</Modal.Body>
		<Modal.Footer>
			<Button variant='secondary' onClick={handleClose}>
				No
			</Button>
			<Button variant='primary' onClick={() => uploadVideo() && handleClose()}>
				Yes
			</Button>
		</Modal.Footer>
	</Modal>
);

export default OverwriteModal;
