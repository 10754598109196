import React from "react";
import Form from "react-bootstrap/Form";
import capitalize from "lodash/capitalize";

const TransitionSelector = ({ disabled, onChange, selected, readOnly, ...props }) => {
    const transitionTypes = ["none", "fade", "vertical-push", "horizontal-wipe", "circle-out"];

    return (
        <Form.Group {...props} controlId="transition">
            <Form.Label>Transition Type</Form.Label>
            <Form.Control
                name="transition"
                as="select"
                onChange={onChange}
                value={selected}
                disabled={disabled}
                readOnly={readOnly}
            >
                {transitionTypes.map((type, index) => (
                    <option key={index} value={type}>
                        {capitalize(type)}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

TransitionSelector.defaultProps = {
    disabled: false,
    readOnly: false,
};

export default TransitionSelector;
