import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
// import compact from 'lodash/compact';
// import flatten from 'lodash/flatten';
// import flow from 'lodash/flow';
// import uniq from 'lodash/uniq';

import {
	fetchContent,
	downloadDocumentVideo,
	fetchDeckIndexJson,
	fetchDeckBackups,
	fetchWorker,
	fetchVideos,
	fetchAllVaultVideos,
	searchVault,
	uploadVideo,
	postForm,
} from '../redux/actions';

import DeckVideoEditor from '../components/editor/VideoEditor';
import DeckLinkEditor from '../components/editor/LinkEditor';

const Editor = () => {
	const dispatch = useDispatchRequest();
	const { pathname: editor } = useLocation();
	const progress = useSelector((state) => state.progress);
	const username = useSelector((state) => state.login.username);

	const { data: content } = useQuery({
		type: 'FETCH_CONTENT',
		action: fetchContent,
		autoLoad: true,
		multiple: true,
	});
	const { data: aceVideos } = useQuery({
		type: 'FETCH_VIDEOS',
		action: fetchVideos,
		autoLoad: true,
		multiple: true,
	});
	const { data: vaultVideos } = useQuery({
		type: 'FETCH_VAULT_ALL_VIDEOS',
		action: fetchAllVaultVideos,
		autoLoad: true,
	});

	// const decks = flow(flatten, uniq, compact, (arr) =>
	// 	arr.map((o) => o.vaultId)
	// )(content);

	return editor === '/video' ? (
		<DeckVideoEditor
			decks={content}
			progress={progress}
			aceVideos={aceVideos}
			vaultVideos={vaultVideos}
			downloadVideo={(id) => dispatch(downloadDocumentVideo(id))}
			uploadVideo={(formData) => dispatch(uploadVideo(formData))}
			username={username}
			fetchDeck={(vaultId) => dispatch(fetchDeckIndexJson(vaultId))}
			fetchDeckBackups={(vaultId) => dispatch(fetchDeckBackups(vaultId))}
			fetchWorker={(id) => dispatch(fetchWorker(id))}
			fetchAceVideos={() => dispatch(fetchVideos())}
			fetchVaultVideos={() => dispatch(fetchAllVaultVideos())}
			searchVault={(vaultId) => dispatch(searchVault(vaultId))}
			postForm={(formData) => dispatch(postForm(formData))}
		/>
	) : (
		<DeckLinkEditor
			decks={content}
			fetchDeck={(vaultId) => dispatch(fetchDeckIndexJson(vaultId))}
			fetchDeckBackups={(vaultId) => dispatch(fetchDeckBackups(vaultId))}
		/>
	);
};

export default Editor;
