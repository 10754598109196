import { success, error, abort } from '@redux-requests/core'
import types from '../types';

const initialState = {
  data: '',
  pending: 0,
  error: '',
  loggedInAs: null,
  username: '',
  password: '',
  passwordIsGood: false,
  sessionIsGood: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.LOGIN:
      return { ...state, pending: state.pending + 1 };
    case success(types.LOGIN):
      return {
        ...state,
        passwordIsGood: true,
        sessionIsGood: true,
        data: action.response.data,
        loggedInAs: state.username,
        pending: state.pending - 1,
        error: null,
      }
    case error(types.LOGIN):
      return {
        ...state,
        passwordIsGood: false,
        sessionIsGood: false,
        data: null,
        pending: state.pending - 1,
        error: action.error,
      }
    case abort(types.LOGIN):
      return { ...state, pending: state.pending - 1 };
    case types.LOGOUT:
      return {
        ...state,
        data: '',
        loggedInAs: null,
        passwordIsGood: false,
        sessionIsGood: false
      };
    case types.UPDATE_LOGIN_FORM:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    case types.UPDATE_SESSION_ID:
      return {
        ...state,
        passwordIsGood: true,
        sessionIsGood: true,
        data: action.payload
      };
    case types.GET_SESSION_ID:
      return state.data;
    default:
      return state;
  }
}

export default loginReducer;
