import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const CountrySelector = ({ onChange, disabled, selected, countries, readOnly }) => {
	return (
		<Form.Group as={Col} controlId='countryCodes'>
			<Form.Label>Country Codes</Form.Label>
			<Form.Control
				custom
				name='countryCodes'
				as='select'
				multiple
				onChange={onChange}
				value={selected}
				disabled={disabled}
				readOnly={readOnly}
			>
				{countries &&
					countries?.map((o) => (
						<option key={o.id} value={o.abbreviation__vs.toUpperCase()}>
							{o.name__v}
						</option>
					))}
			</Form.Control>
		</Form.Group>
	);
}

CountrySelector.defaultProps = {
	disabled: false,
	readOnly: false
};

export default CountrySelector;
