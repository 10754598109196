import React from 'react';

const Warning = () => (
	<div className='screenAlert-icon screenAlert-warning scaleWarning'>
		<span className='screenAlert-body pulseWarningIns'></span>
		<span className='screenAlert-dot pulseWarningIns'></span>
	</div>
);

export default Warning;
