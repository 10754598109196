import React from 'react';
import Alert from 'react-bootstrap/Alert';
import capitalize from 'lodash/capitalize';

const StandaloneAlerts = ({
	file,
	done,
	error,
	warn,
	mode,
	setDone,
	setError,
	setWarn,
}) => {
	const fileType = () => capitalize(file);

	return (
		<>
			{done && mode === 'deploy' ? (
				<Alert variant='success' dismissible onClose={() => setDone(false)}>
					{fileType()} uploaded successfully, check tasks tab for progress
				</Alert>
			) : (
				done &&
				mode === 'update' && (
					<Alert variant='success' dismissible onClose={() => setDone(false)}>
						{fileType()} updated successfully
					</Alert>
				)
			)}
			{error && (
				<Alert variant='danger' dismissible onClose={() => setError(false)}>
					{error ||
						'There was an error processing your request, check the console'}
				</Alert>
			)}
			{warn && (
				<Alert variant='warning' dismissible onClose={() => setWarn(false)}>
					Warning: {warn}
				</Alert>
			)}
		</>
	);
};

export default StandaloneAlerts;
