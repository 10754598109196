import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';

import { useDispatchRequest } from '@redux-requests/react';
import { getQuerySelector } from '@redux-requests/core';
import { useSelector } from 'react-redux';

import * as Actions from '../redux/actions';

const Loading = () => (
	<Row>
		<Col
			className='d-flex justify-content-center align-items-center'
			style={{ height: '80vh' }}
		>
			<Spinner
				animation='border'
				role='status'
				variant='primary'
				style={{ height: '5rem', width: '5rem' }}
			>
				<span className='sr-only'>Loading...</span>
			</Spinner>
		</Col>
	</Row>
);

const RouteTest = () => {
	const dispatch = useDispatchRequest();
	const [loading, setLoading] = useState(false);
	const [responses, setResponses] = useState({});

	const requests = Object.values(Actions).filter(
		(action) => action.name.includes('fetch') && action.length === 0
	);
	const types = requests.reduce(
		(funcs, func) => ({
			...funcs,
			[func.name]: snakeCase(func.name).toUpperCase(),
		}),
		{}
	);

	const _responses = requests.map((func) =>
		useSelector(getQuerySelector({ type: [types[func.name]] }))
	);

	useEffect(() => {
		const fetchAll = async () => {
			setLoading(true);
			setResponses(
				(await Promise.all(requests.map((func) => dispatch(func())))).reduce(
					(__responses, res, index) => ({
						...__responses,
						[requests[index].name]: res,
					}),
					{}
				)
			);
			setLoading(false);
		};
		fetchAll();
	}, []);

	return (
		<Container>
			<ListGroup>
				{requests.map((req) => {
					return responses[req.name]?.status === 200 ? (
						<ListGroup.Item action variant='success'>
							{startCase(req.name)}
						</ListGroup.Item>
					) : (
						<ListGroup.Item action variant='danger'>
							{startCase(req.name)}
						</ListGroup.Item>
					);
				})}
			</ListGroup>
		</Container>
	);
};

export default RouteTest;
