import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { connect } from 'react-redux';

import Finalize from './Finalize';
import UploaderForm from './Form';

import { handleError } from '../helpers';
import { requestSelector } from '../../../redux/selectors';

import {
	authorizeUser,
	checkVaultDeckIsWideScreen,
	checkAceDeckIsWideScreen,
	downloadDocumentRendition,
	downloadDocumentSource,
	downloadDocumentVideo,
	downloadDocumentThumbnail,
	downloadDocumentAudio,
	dereferenceRelationships,
	fetchNewCategories,
	fetchContent,
	fetchCountryCodes,
	fetchDeckIndexJson,
	fetchDeckBackups,
	fetchAllVaultVideos,
	fetchVaultDeck,
	fetchVideos,
	fetchWorker,
	fetchLastWorker,
	fetchLocals,
	fetchStagedIndexJson,
	fetchPdfThumbnail,
	fetchPodcastThumbnail,
	postForm,
	publish,
	tokenize,
	searchVault,
	updateForm,
	updateDeckData,
	updateSessionId,
	uploadPdfThumbnail,
	uploadVideoThumbnail,
	uploadVideo,
} from '../../../redux/actions';

import { version } from '../../../../package.json';

const mapStateToProps = (state) => ({
	countries: requestSelector('FETCH_VAULT_COUNTRY_CODES', state),
	newCategories: requestSelector('FETCH_VAULT_NEW_CATEGORIES', state),
	aceVideos: requestSelector('FETCH_VIDEOS', state),
	vaultVideos: requestSelector('FETCH_VAULT_ALL_VIDEOS', state),
	form: state.form,
	progress: state.progress,
	locals: requestSelector('FETCH_LOCALS', state, { languages: [] })
});

const mapDispatchToProps = (dispatch) => ({
	authorizeUser: (sessionId) => dispatch(authorizeUser(sessionId)),
	downloadRendition: (id) => dispatch(downloadDocumentRendition(id)),
	downloadSource: (id) => dispatch(downloadDocumentSource(id)),
	downloadVideo: (id) => dispatch(downloadDocumentVideo(id)),
	downloadThumbnail: (id) => dispatch(downloadDocumentThumbnail(id)),
	downloadAudio: (id) => dispatch(downloadDocumentAudio(id)),
	fetchNewCategories: () => dispatch(fetchNewCategories()),
	fetchCountryCodes: () => dispatch(fetchCountryCodes()),
	fetchContent: (docType) => dispatch(fetchContent(docType)),
	fetchDeck: (vaultId) => dispatch(fetchDeckIndexJson(vaultId)),
	fetchAceVideos: () => dispatch(fetchVideos()),
	fetchVaultVideos: () => dispatch(fetchAllVaultVideos()),
	fetchLocals: () => dispatch(fetchLocals()),
	fetchWorker: (id) => dispatch(fetchWorker(id)),
	fetchLastWorker: (vaultId) => dispatch(fetchLastWorker(vaultId)),
	fetchStagedIndexJson: (workerId) => dispatch(fetchStagedIndexJson(workerId)),
	fetchDeckBackups: (vaultId) => dispatch(fetchDeckBackups(vaultId)),
	fetchPdfThumbnail: (vaultId) => dispatch(fetchPdfThumbnail(vaultId)),
	fetchPodcastThumbnail: (id) => dispatch(fetchPodcastThumbnail(id)),
	tokenize: ({ id, vaultId, contentType }) =>
		dispatch(tokenize({ id, vaultId, contentType })),
	postForm: (formData) => dispatch(postForm(formData)),
	publish: (formData) => dispatch(publish(formData)),
	searchVault: (vaultId) => dispatch(searchVault(vaultId)),
	updateDeckData: (formData) => dispatch(updateDeckData(formData)),
	updateForm: (name, value) => dispatch(updateForm(name, value)),
	updateSessionId: (sessionId) => dispatch(updateSessionId(sessionId)),
	uploadPdfThumbnail: (vaultId, formData) =>
		dispatch(uploadPdfThumbnail(vaultId, formData)),
	uploadVideoThumbnail: (vaultId, formData) =>
		dispatch(uploadVideoThumbnail(vaultId, formData)),
	uploadVideo: (formData) => dispatch(uploadVideo(formData)),
	dereferenceRelationships: (id) => dispatch(dereferenceRelationships(id)),
	fetchVaultDeck: (vaultId) => dispatch(fetchVaultDeck(vaultId)),
	checkAceDeckIsWideScreen: (vaultId) => dispatch(checkAceDeckIsWideScreen(vaultId)),
	checkVaultDeckIsWideScreen: (vaultId) => dispatch(checkVaultDeckIsWideScreen(vaultId))
});

class VaultUploader extends React.Component {
	state = {
		alert: false,
		authorized: false,
		done: false,
		documentUrl: null,
		documentType: null,
		error: null,
		imageBlob: null,
		message: null,
		loading: false,
		openPdfThumbnailEditor: false,
		openVideoThumbnailEditor: false,
		openVideoEditor: false,
		openLinkEditor: false,
		published: false,
		test: false,
		uploaded: false,
		username: '',
		vaultId: '',
		workerId: '',
		conditions: '',
		departments: '',
	};

	async componentDidMount() {
		const { updateSessionId } = this.props;
		const url = new URL(document.location.href);

		this.setState({ loading: true });

		try {
			if (url.search) {
				const params = new URLSearchParams(url.search);
				const query = {
					vaultId: params.get('vaultId'),
					vaultDomain: params.get('vaultDomain'),
					sessionId: params.get('sessionId'),
					docName: params.get('docName'),
				};

				console.log('sessionId:', query.sessionId);

				updateSessionId(query.sessionId);

				await Promise.race([
					this.props.fetchCountryCodes(),
					this.props.fetchLocals(),
					this.props.fetchNewCategories(),
					this.props.fetchAceVideos(),
					this.props.fetchVaultVideos(),
				]);

				const { data: doc } = await this.props.fetchVaultDeck(query.vaultId);

				console.log('[VAULT DOC]: ', doc);

				if (
					query.vaultDomain === 'sb-abiomed-promomats.veevavault.com' ||
					query.vaultDomain === 'sb-abiomd-promomats-qa.veevavault.com'
				) {
					this.setState({ test: true });
				}
				// $FlowFixMe
				await this.authorize(query.sessionId);
				this.setState({ 
					vaultId: query.vaultId, 
					docName: query.docName, 
					conditions: doc.condition__c, 
					departments: doc.department__c 
				});
			}
		} catch (err) {
			this.toggleAlert('danger', handleError(err));
		}
	}

	async authorize(sessionId) {
		const { authorizeUser } = this.props;

		this.setState({ loading: true });

		try {
			const { data: user } = await authorizeUser(sessionId);

			console.log('User: ', user);

			this.setState({
				username: user.username__sys,
				authorized: user.ace_admin__c,
				loading: false,
			});
		} catch (err) {
			console.log(err);
			this.setState({ loading: false });
		}
	}

	toggleAlert = (variant, message) => {
		this.setState({
			alert: variant,
			message,
		});
	};

	renderForm() {
		const {
			authorized,
			documentType,
			loading,
			uploaded,
			vaultId,
			docName,
			departments,
			conditions,
		} = this.state;

		const { updateForm } = this.props;

		if (loading && !authorized) {
			return (
				<>
					<Breadcrumb className='pt-5'>
						<Breadcrumb.Item active>1. Authentication</Breadcrumb.Item>
						<Breadcrumb.Item>2. Upload</Breadcrumb.Item>
						<Breadcrumb.Item>3. Finalize</Breadcrumb.Item>
					</Breadcrumb>
					<Jumbotron>
						<h1>Authenticating...</h1>
					</Jumbotron>
				</>
			);
		} else if (!authorized) {
			return (
				<>
					<Breadcrumb className='pt-5'>
						<Breadcrumb.Item active>1. Authentication</Breadcrumb.Item>
						<Breadcrumb.Item>2. Upload</Breadcrumb.Item>
						<Breadcrumb.Item>3. Finalize</Breadcrumb.Item>
					</Breadcrumb>
					<Jumbotron>
						<h1>You are not authorized to perform this function</h1>;
					</Jumbotron>
				</>
			);
		} else if (!uploaded) {
			const { documentUrl, error, username, uploaded } = this.state;

			const {
				newCategories,
				countries,
				form,
				locals,
				downloadAudio,
				downloadRendition,
				downloadSource,
				downloadVideo,
				downloadThumbnail,
				fetchContent,
				fetchVaultDeck,
				fetchWorker,
				fetchLastWorker,
				fetchPdfThumbnail,
				fetchPodcastThumbnail,
				tokenize,
				searchVault,
				postForm,
				updateDeckData,
				dereferenceRelationships,
				checkVaultDeckIsWideScreen,
				checkAceDeckIsWideScreen,
			} = this.props;

			return (
				<UploaderForm
					checkVaultDeckIsWideScreen={checkVaultDeckIsWideScreen}
					checkAceDeckIsWideScreen={checkAceDeckIsWideScreen}
					newCategories={newCategories}
					languages={locals?.languages}
					countries={countries}
					locals={locals}
					documents={[{ document_number__v: vaultId, name__v: docName, condition__c: conditions, department__c: departments }]}
					documentUrl={documentUrl}
					error={error}
					form={form}
					username={username}
					uploaded={uploaded}
					vaultId={vaultId}
					downloadAudio={downloadAudio}
					downloadRendition={downloadRendition}
					downloadSource={downloadSource}
					downloadVideo={downloadVideo}
					downloadThumbnail={downloadThumbnail}
					dereferenceRelationships={dereferenceRelationships}
					fetchVaultDeck={fetchVaultDeck}
					fetchWorker={fetchWorker}
					fetchLastWorker={fetchLastWorker}
					fetchContent={fetchContent}
					fetchPdfThumbnail={fetchPdfThumbnail}
					fetchPodcastThumbnail={fetchPodcastThumbnail}
					tokenize={tokenize}
					searchVault={searchVault}
					setWorkerId={(id) => this.setState({ workerId: id })}
					setDocumentType={(docType) =>
						this.setState({ documentType: docType })
					}
					setDocumentUrl={(docUrl) => this.setState({ documentUrl: docUrl })}
					setImageBlob={(blob) => this.setState({ imageBlob: blob })}
					toggleUploaded={() =>
						this.setState({ uploaded: !this.state.uploaded })
					}
					toggleAlert={this.toggleAlert}
					updateDeckData={updateDeckData}
					updateForm={updateForm}
					postForm={postForm}
				/>
			);
		} else {
			const {
				downloadVideo,
				form,
				aceVideos,
				vaultVideos,
				progress,
				fetchDeck,
				fetchAceVideos,
				fetchVaultVideos,
				fetchWorker,
				fetchStagedIndexJson,
				fetchDeckBackups,
				postForm,
				publish,
				uploadPdfThumbnail,
				uploadVideoThumbnail,
				uploadVideo,
			} = this.props;

			const {
				documentUrl,
				error,
				username,
				imageBlob,
				workerId,
				openPdfThumbnailEditor,
				openVideoThumbnailEditor,
				openVideoEditor,
				openLinkEditor,
				published,
			} = this.state;

			return (
				<Finalize
					error={error}
					form={form}
					documentUrl={documentUrl}
					documentType={documentType}
					imageBlob={imageBlob}
					openLinkEditor={openLinkEditor}
					openPdfThumbnailEditor={openPdfThumbnailEditor}
					openVideoThumbnailEditor={openVideoThumbnailEditor}
					openVideoEditor={openVideoEditor}
					progress={progress}
					published={published}
					workerId={workerId}
					fetchDeck={fetchDeck}
					fetchAceVideos={fetchAceVideos}
					fetchVaultVideos={fetchVaultVideos}
					fetchWorker={fetchWorker}
					fetchStagedIndexJson={fetchStagedIndexJson}
					fetchDeckBackups={fetchDeckBackups}
					fetchPodcastThumbnail={fetchPodcastThumbnail}
					searchVault={searchVault}
					postForm={postForm}
					uploadPdfThumbnail={uploadPdfThumbnail}
					uploadVideoThumbnail={uploadVideoThumbnail}
					username={username}
					vaultId={vaultId}
					aceVideos={aceVideos}
					vaultVideos={vaultVideos}
					downloadVideo={downloadVideo}
					updateForm={updateForm}
					uploadVideo={uploadVideo}
					toggleAlert={this.toggleAlert}
					toggleError={(err) => this.setState({ error: err })}
					togglePdfThumbnailEditor={() =>
						this.setState({
							openPdfThumbnailEditor: !this.state.openPdfThumbnailEditor,
						})
					}
					togglePublished={() =>
						this.setState({ published: !this.state.published })
					}
					toggleVideoThumbnailEditor={() =>
						this.setState({
							openVideoThumbnailEditor: !this.state.openVideoThumbnailEditor,
						})
					}
					toggleVideoEditor={() =>
						this.setState({ openVideoEditor: !this.state.openVideoEditor })
					}
					toggleLinksEditor={() =>
						this.setState({ openLinkEditor: !this.state.openLinkEditor })
					}
					publish={publish}
				/>
			);
		}
	}

	render() {
		const { alert, message } = this.state;

		return (
			<Container>
				<Alert
					className='mt-2'
					variant={alert}
					show={!!alert}
					dismissible
					onClose={() => this.toggleAlert(false)}
				>
					{message}
				</Alert>
				{this.renderForm()}
				<footer className='float-right'>
					<code className='pr-3'>
						VERSION: {version} |{' '}
						{window.location.href.includes('test') ||
							window.location.href.includes('localhost')
							? 'ENV: TEST'
							: 'ENV: LIVE'}
					</code>
				</footer>
			</Container>
		);
	}
}

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(VaultUploader);
