import { StyleSheet, css } from 'aphrodite';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import Hotkeys from 'react-hot-keys';
import clone from 'lodash/clone';
import has from 'lodash/has';
import get from 'lodash/get';
import noop from 'lodash/noop';

import ChooseLinkModal from './ChooseLinkModal';
import HelpModal from '../help/linkEditorHelp';
import ResultModal from '../shared/ResultModal';
import Carousel from './Carousel';
import EditorButtonGroup from './EditorButtonGroup';
import EditorSlideSelector from './EditorSlideSelector';
import CopyLinksComponent from './CopyLinksSelector';

import { state, initialize, setSelectedLinks } from './helpers/links';
import { getVaultId } from './helpers';

const styles = StyleSheet.create({
	pre: {
		backgroundColor: '#e9ecef',
		width: '100%',
	},
	json: {
		paddingTop: '10px',
	},
});

class DeckLinkEditor extends React.Component {
	constructor(props) {
		super(props);
		this.image;
		this.state = clone(state);

		this.initialize(this);
	}

	initialize = initialize.bind(this);

	changeDeck = ([deck]) => {
		if (!deck) return;

		const vaultId = getVaultId(deck);

		console.log('deck: ', deck);

		this.setState(
			{
				deck,
				isDam: vaultId?.includes('DAM'),
			},
			async () => {
				deck ? await this.getDeckJson({ vaultId }) : noop;
				console.log('[IS DAM?]', this.state.isDam);
			}
		);
	};

	componentDidMount() {
		if (typeof window.onresize === 'function') {
			window.onresize = null;
		}

		window.onresize = this.setScale;
	}

	componentWillUnmount() {
		window.onresize = null;
	}

	render() {
		const { decks } = this.props;

		const {
			crop,
			damJson,
			done,
			deck,
			deckIsLoaded,
			deckIsInvalid,
			error,
			images,
			isDam,
			loading,
			arrayNumber,
			selectedSlide,
			selectedLinks,
			slides,
			showJson,
			showHelp,
			showChooseLinkModal,
			showResultModal,
		} = this.state;

		const slide = slides[selectedSlide] || {};
		const hasUrls = !!slide.links;

		const hasRect =
			has(slides, [selectedSlide, 'links', arrayNumber, 'rect']);

		return (
			<Hotkeys
				keyName='shift+j'
				onKeyDown={() => this.setState({ showJson: !this.state.showJson })}
			>
				<Container>
					{done && (
						<Alert
							variant='success'
							dismissible
							onClose={() => this.setState({ done: false })}
						>
							Json uploaded successfully
						</Alert>
					)}
					{error && (
						<Alert
							variant='danger'
							dismissible
							onClose={() => this.setState({ error: false })}
						>
							There was an error, check the console
						</Alert>
					)}
					<Form>
						<Form.Row>
							<h1>Link Editor</h1>
							<p
								onClick={() =>
									this.setState({ showHelpModal: !this.state.showHelp })
								}
							>
								ⓘ
							</p>
						</Form.Row>
						<Form.Row>
							<Carousel
								arrayNumber={arrayNumber}
								crop={crop}
								deck={deck.vaultId}
								deckIsLoaded={deckIsLoaded}
								deckIsInvalid={deckIsInvalid}
								hasObject={hasUrls}
								images={images}
								slides={slides}
								selectedLinks={selectedLinks}
								setSelectedLinks={setSelectedLinks.bind(this)}
								selectedSlide={selectedSlide}
								changeSelectedObject={this.changeLinkNumber}
								onCropChange={this.onCropChange}
								onImageLoaded={this.onImageLoaded}
								nextSlide={this.nextSlide}
								prevSlide={this.prevSlide}
								property={this.state.property}
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='deck'>
								<Form.Label>Deck</Form.Label>
								<Typeahead
									id='deck-typeahead'
									options={decks || []}
									labelKey={option => getVaultId(option)}
									placeholder='Enter deck vault ID'
									onChange={this.changeDeck}
									isInvalid={deckIsInvalid}
									isValid={deckIsLoaded && !deckIsInvalid}
								/>
								<Form.Control.Feedback type='invalid'>
									Deck is invalid
								</Form.Control.Feedback>
								<Form.Control.Feedback type='valid'>
									Deck is valid
								</Form.Control.Feedback>
							</Form.Group>
							<EditorSlideSelector
								deckIsLoaded={deckIsLoaded}
								property={this.state.property}
								changeSlide={this.changeSlide}
								slides={slides}
								selectedSlide={selectedSlide}
							/>
						</Form.Row>
						<CopyLinksComponent 
							slides={slides} 
							sourceSlideId={slide.id} 
							copyLinksToSlides={this.copyLinksToSlides}
							setSlides={(slides) => this.setState({ slides })}
						/>
						<EditorButtonGroup
							property={this.state.property}
							togglePropertyModal={this.toggleLinkModal}
							clear={this.clear}
							deckIsLoaded={deckIsLoaded}
							deckIsInvalid={deckIsInvalid}
							hasRect={hasRect}
						/>
						<Form.Row>
							<Form.Group as={Col}>
								<Button
									variant='primary'
									onClick={this.downloadJson}
									disabled={!deckIsLoaded}
									block
								>
									Download JSON
								</Button>
								<Button
									variant='primary'
									block
									onClick={() => this.submit()}
									disabled={!deckIsLoaded || deckIsInvalid}
								>
									{loading && (
										<Spinner
											as='span'
											animation='grow'
											role='status'
											aria-hidden='true'
											size='sm'
										/>
									)}
									Save
								</Button>
							</Form.Group>
						</Form.Row>
					</Form>
					<Collapse in={showJson}>
						<Row className={css(styles.json)}>
							<Col>
								<pre className={css(styles.pre)}>
									{JSON.stringify(isDam ? damJson : slides, null, 3)}
								</pre>
							</Col>
						</Row>
					</Collapse>
				</Container>
				<HelpModal show={showHelp} handleClose={this.toggleHelpModal} />
				<ChooseLinkModal
					hasUrls={hasUrls}
					show={showChooseLinkModal}
					deckIsLoaded={deckIsLoaded}
					url={get(slides, [selectedSlide, 'links', arrayNumber, 'url'], '')}
					onChange={this.onChange}
					restoreOldVals={this.restoreOldVals}
					handleClose={this.toggleLinkModal}
				/>
				<ResultModal
					show={showResultModal}
					vaultId={deck.vaultId}
					error={error}
					successMsg={`${deck.vaultId} was successfully updated`}
					errMsg={error || `There was an error updating ${deck.vaultId}`}
					handleClose={() =>
						this.setState({ showResultModal: !this.state.showResultModal })
					}
				/>
			</Hotkeys>
		);
	}
}

export default DeckLinkEditor;
