import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import throttle from 'lodash/throttle';

import FileInput from '../editor/FileInputWithUpload';

import { handleError, base64ArrayBuffer } from './helpers';

const ThumbnailEditor = ({
	documentUrl,
	thumbnail,
	imageBlob,
	vaultId,
	toggleError,
	updateForm,
	uploadPdfThumbnail,
}) => {
	const [crop, setCrop] = useState({
		x: 0,
		y: 0,
		height: 35,
		width: 80,
		unit: '%',
	});
	const [imageScale, setImageScale] = useState({
		scaleX: 0,
		scaleY: 0,
	});
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(thumbnail || null);
	const [imageFile, setImageFile] = useState(null);

	let imageRef;

	useEffect(() => {
		setImageFile(
			new File([imageBlob], `${vaultId}-thumbnail.png`, { type: 'image/png' })
		);
	}, [imageBlob]);

	const onImageLoaded = (image) => {
		imageRef = image;

		setImageScale({
			scaleX: image.naturalWidth / image.width,
			scaleY: image.naturalHeight / image.height,
		});

		return false;
	};

	const onCropChange = (crop, percentCrop) => {
		setCrop(percentCrop);
	};

	const uploadCustom = async ({ currentTarget: { files } }) => {
		try {
			if (files && files.length > 0) {
				const file = files[0];
				const formData = new FormData();
				formData.append('vaultId', vaultId);
				formData.append('status', status);
				formData.append('image', file);

				setLoading(true);

				const { data: image } = await uploadPdfThumbnail(vaultId, formData);
				const base64String = base64ArrayBuffer(image);
				setImage(base64String);
				setLoading(false);

				updateForm({
					customThumbnail: true,
					image: imageBlob,
				});
			}
		} catch (err) {
			toggleError(handleError(err));
		}
	};

	const upload = async (crop) => {
		const formData = new FormData();
		formData.append('vaultId', vaultId);
		formData.append('status', status);
		formData.append('crop', JSON.stringify(crop));
		formData.append('image', imageFile);
		formData.append('imageScale', JSON.stringify(imageScale));

		setLoading(true);

		const { data: image } = await uploadPdfThumbnail(vaultId, formData);
		const base64String = base64ArrayBuffer(image);

		setImage(base64String);
		setLoading(false);

		updateForm({
			customThumbnail: false,
			crop: JSON.stringify(crop),
			image: imageBlob,
			imageScale: JSON.stringify(imageScale),
		});
	};

	const onCropComplete = throttle(upload, 400, { trailing: false }).bind(
		ThumbnailEditor
	);

	return (
		<Form>
			<Form.Row>
				<Form.Group as={Col} md={6}>
					<Card className='mx-auto'>
						<Card.Header>Set thumbnail</Card.Header>
						<ReactCrop
							className='mx-auto'
							src={documentUrl}
							crop={crop}
							minHeight={64}
							minWidth={64}
							maxHeight={imageRef && imageRef.height}
							maxWidth={imageRef && imageRef.width}
							onImageLoaded={onImageLoaded}
							onComplete={onCropComplete}
							onChange={onCropChange}
							imageStyle={{
								maxWidth: '100%',
								maxHeight: '100%',
							}}
						/>
					</Card>
				</Form.Group>
				<Form.Group as={Col} md={6}>
					<Form.Group as={Col}>
						<Card>
							<Card.Header>ACE Preview</Card.Header>
							<Card.Body className='d-flex justify-content-center'>
								{loading ? (
									<Spinner animation='border' role='status'>
										<span className='sr-only'>Loading...</span>
									</Spinner>
								) : (
									<img
										alt='crop'
										width='310px'
										height='213.4px'
										src={
											image ||
											require('../../../public/images/missing_1730_1.png')
										}
									/>
								)}
							</Card.Body>
						</Card>
					</Form.Group>
					<Form.Group as={Col} controlId='image'>
						<Form.Label>Upload Custom Thumbnail</Form.Label>
						<FileInput
							name='thumbnail'
							id='thumbnail'
							accept='image/*'
							onChange={uploadCustom}
						/>
					</Form.Group>
				</Form.Group>
			</Form.Row>
		</Form>
	);
};

export default ThumbnailEditor;
