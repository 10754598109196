import React from 'react';
import { useQuery } from '@redux-requests/react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Spinner from 'react-bootstrap/Spinner';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import VideoSelect from './VideoSelect';

import { getVideoPath } from './helpers/videos';

const ChooseVideoModal = ({
	arrayNumber,
	show,
	deckIsLoaded,
	file,
	slides,
	selectedSlide,
	videos,
	upload,
	progress,
	oldVals,
	downloadVideo,
	restoreOldVals,
	setUpload,
	setVaultVideo,
	uploadVideo,
	clearVideo,
	onFileChange,
	onPathChange,
	handleClose,
}) => {
	const vaultId = get(
		slides,
		[selectedSlide, 'video', arrayNumber, 'path'],
		get(oldVals, [arrayNumber, 'path'])
	);
	const videoSrc = getVideoPath(
		get(
			slides,
			[selectedSlide, 'video', arrayNumber, 'path'],
			get(oldVals, [arrayNumber, 'path'])
		)
	);
	const { load: checkVideo } = useQuery({ type: 'CHECK_IF_VIDEO_EXISTS' });
	const slide = slides[selectedSlide];

	const hasVideo = !isUndefined(vaultId) && !videoSrc.includes('undefined');
	const autoPlay = hasVideo;

	const handleCloseWithoutSaving = () => {
		if (restoreOldVals) {
			restoreOldVals();
		}
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleCloseWithoutSaving}>
			<Modal.Header>
				<Modal.Title>{hasVideo ? 'Edit video' : 'Add video'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Collapse in={hasVideo}>
					<Col className='text-center'>
						{hasVideo ? (
							<video
								autoPlay={autoPlay}
								controls
								muted
								playsInline
								loop
								style={{ width: '100%', height: '250px' }}
								src={videoSrc}
								onError={(event) => {
									if (!isUndefined(vaultId) && !isEmpty(vaultId)) {
										const interval = setInterval(async () => {
											const { data: status } = await checkVideo(vaultId);
											console.log('error: ', status);
											if (status.includes('FAILURE')) {
												console.log('Error fetching video from Vault');
												clearInterval(interval);
											} else if (status.error.includes('ENOENT')) {
												clearInterval(interval);
											} else {
												event.target?.load();
												clearInterval(interval);
											}
										}, 2500);
										setTimeout(() => clearInterval(interval), 100000);
									}
								}}
							></video>
						) : (
							<Spinner
								animation='border'
								role='status'
								variant='primary'
								style={{ height: '5rem', width: '5rem' }}
							>
								<span className='sr-only'>Loading...</span>
							</Spinner>
						)}
					</Col>
				</Collapse>
				<Form>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Video Source</Form.Label>
							<ToggleButtonGroup
								type='radio'
								name='upload'
								style={{ width: '100%' }}
								defaultValue={[upload]}
								onChange={setUpload}
							>
								<ToggleButton variant='outline-secondary' value={1}>
									Pick from Vault
								</ToggleButton>
								<ToggleButton variant='outline-secondary' value={2}>
									Pick from ACE Pro
								</ToggleButton>
								<ToggleButton variant='outline-secondary' value={3}>
									Upload from desktop
								</ToggleButton>
							</ToggleButtonGroup>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<VideoSelect
							arrayNumber={arrayNumber}
							deckIsLoaded={deckIsLoaded}
							hasVideo={hasVideo}
							slide={slide}
							upload={upload}
							progress={progress}
							videos={videos}
							downloadVideo={downloadVideo}
							onFileChange={onFileChange}
							onPathChange={onPathChange}
							setVaultVideo={setVaultVideo}
							uploadVideo={uploadVideo}
						/>
					</Form.Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleCloseWithoutSaving}>
					Cancel
				</Button>
				<Button variant='primary' onClick={handleClose}>
					Select
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

ChooseVideoModal.defaultProps = {
	slides: [],
};

export default ChooseVideoModal;
