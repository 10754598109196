import React from 'react';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Collapse from 'react-bootstrap/Collapse';
import isNull from 'lodash/isNull';
import replace from 'lodash/replace';

import PdfThumbnailEditor from '../PdfThumbnailEditor';
import VideoThumbnailEditor from '../VideoThumbnailEditor';
import VideoEditor from '../VideoEditor';
import LinkEditor from '../LinkEditor';

import ResultModal from '../../shared/ResultModal';
import JsonViewer from '../JsonViewer';

import { handleError } from '../helpers';
import {
	getDeckJson,
	preloadImages,
	manuallyUpdateJson,
} from '../../editor/helpers';

import { POWERPOINT, VIDEO, AUDIO } from '../constants';
import { isPdf, isAudio, isVideo, isPowerpoint } from '../helpers';

class Finalize extends React.Component {
	state = {
		deck: '',
		showResultModal: false,
		slides: [],
		images: [],
		isDam: false,
		damJson: {},
		deckIsLoaded: false,
		deckIsInvalid: true,
		backup: this.props.form.useExperimentalImageMatcher
			? 'expIndexJson'
			: 'sqlIndexJson'
	};

	componentDidMount() {
		const {
			documentType,
			vaultId,
			workerId,
			form: { operation },
		} = this.props;

		console.log('deck info: ', workerId, vaultId);
		console.log('operation: ', operation);

		if (isPowerpoint(documentType)) {
			this.setState(
				{
					deck: vaultId,
					isDam: vaultId.includes('DAM'),
				},
				async () => {
					await this.getDeckJson({
						vaultId,
						vaultIntegrated: true,
						job: workerId,
						useAutomaticVideoAndLinkPlacement: this.props.form.useAutomaticVideoAndLinkPlacement,
						useExperimentalImageMatcher: this.props.form.useExperimentalImageMatcher,
						update: operation === 'update',
					});
					console.log('is dam?: ', this.state.isDam);
				}
			);
		}
	}

	getDeckJson = getDeckJson.bind(this);

	preloadImages = preloadImages.bind(this);

	manuallyUpdateJson = manuallyUpdateJson.bind(this);

	toggleResultModal = () => {
		this.setState({ showResultModal: !this.state.showResultModal });
	};

	publish = async () => {
		const { documentType, form, publish } = this.props;

		const formData = new FormData();
		formData.append('animated', form.animated);
		formData.append('mainCategory', form.mainCategory);
		formData.append('secondCategory', form.secondCategory);
		formData.append('keywords', form.keywords);
		formData.append('title', replace(form.title, '/', '-'));
		formData.append('languageCode', form.languageCode);
		formData.append('contentZone', form.contentZone);
		formData.append('countryCodes', form.countryCodes.toString());
		formData.append('permissions', form.permissions);
		formData.append('qpa', form.qpa);
		formData.append('status', form.status);
		formData.append('vaultId', form.vaultId);
		formData.append('username', this.props.username);
		formData.append('operation', form.operation);
		formData.append('exposure', form.exposure);
		formData.append('grouped', form.grouped);
		formData.append('documentId', form.documentId);
		formData.append('relatedItems', form.relatedItems);
		formData.append('descriptor', form.aceProDescriptor);
		formData.append('test', form.test);
		formData.append('transition', form.transition);
		formData.append('departments', form.departments ? form.departments.join(',') : '');
		formData.append('conditions', form.conditions ? form.conditions.join(',') : '');
		formData.append('resizeRects', form.resizeRects);

		if (isPdf(documentType)) {
			if (isNull(form.pdf)) {
				throw new Error('no pdf attached');
			}

			formData.append(
				'image',
				new File([form.image], 'image.png', { type: 'image/png' })
			);

			if (!form.customThumbnail) {
				formData.append('crop', form.crop);
				formData.append('imageScale', form.imageScale);
			}
		} else if (isPowerpoint(documentType)) {
			formData.append(
				'json',
				JSON.stringify(
					this.state.isDam ? this.state.damJson : this.state.slides
				)
			);
			formData.append('zip', new File([], form.fileName, { type: POWERPOINT })); // no need to send the powerpoint again
			formData.append('temp', 'true');

			if (form.notify) {
				formData.append('notify', form.notify.toString());
			}
		} else if (isVideo(documentType)) {
			if (isNull(form.video)) {
				throw new Error('no video file attached');
			}

			formData.append('video', new File([], form.fileName, { type: VIDEO }));
			formData.append('embedded', 'false');
			formData.append('wistiaUrl', form.wistiaUrl);
		} else if (isAudio(documentType)) {
			if (isNull(form.audio)) {
				throw new Error('no audio file attached');
			}

			formData.append('audio', new File([], form.fileName, { type: AUDIO }));
		}

		try {
			await publish(formData);

			// NOTE: this is a very mysterious error object with dissappearing properties
			// if (response.error) {
			// 	throw new Error(response.error);
			// }

			this.toggleResultModal();
		} catch (err) {
			const { toggleError } = this.props;
			toggleError(handleError(err));
			this.toggleResultModal();
		}
	};

	render() {
		const {
			exposure,
			documentType,
			documentUrl,
			error,
			form,
			imageBlob,
			openLinkEditor,
			openVideoEditor,
			progress,
			username,
			vaultId,
			aceVideos,
			vaultVideos,
			useExperimentalImageMatcher,
			downloadVideo,
			fetchDeck,
			fetchAceVideos,
			fetchVaultVideos,
			fetchWorker,
			searchVault,
			postForm,
			toggleError,
			toggleVideoEditor,
			toggleLinksEditor,
			updateForm,
			uploadPdfThumbnail,
			uploadVideoThumbnail,
			uploadVideo,
		} = this.props;

		const {
			images,
			slides,
			damJson,
			isDam,
			deckIsLoaded,
			deckIsInvalid,
			showResultModal,
		} = this.state;

		return (
			<>
				<Breadcrumb className='pt-4'>
					<Breadcrumb.Item>1. Authentication</Breadcrumb.Item>
					<Breadcrumb.Item>2. Upload</Breadcrumb.Item>
					<Breadcrumb.Item active>3. Finalize</Breadcrumb.Item>
					<Button
						onClick={this.publish}
						variant='primary'
						style={{ margin: '-8px' }}
						className='ml-auto'
						disabled={isPdf(documentType) && !form.image ? true : false}
					>
						Publish
					</Button>
				</Breadcrumb>
				{isVideo(documentType) && (
					<VideoThumbnailEditor
						form={form}
						vaultId={vaultId}
						toggleError={toggleError}
						uploadVideoThumbnail={uploadVideoThumbnail}
					/>
				)}
				{isPdf(documentType) && (
					<PdfThumbnailEditor
						documentUrl={documentUrl}
						thumbnail={form.image}
						status={form.status}
						imageBlob={imageBlob}
						vaultId={vaultId}
						toggleError={toggleError}
						updateForm={updateForm}
						uploadPdfThumbnail={uploadPdfThumbnail}
					/>
				)}
				{isPowerpoint(documentType) && (
					<Collapse in={openVideoEditor}>
						<div>
							<VideoEditor
								deckIsLoaded={deckIsLoaded}
								deckIsInvalid={deckIsInvalid}
								images={images}
								isDam={isDam}
								slides={slides}
								damJson={damJson}
								aceVideos={aceVideos}
								progress={progress}
								vaultVideos={vaultVideos}
								vaultId={vaultId}
								useExperimentalImageMatcher={useExperimentalImageMatcher}
								downloadVideo={downloadVideo}
								uploadVideo={uploadVideo}
								fetchDeck={fetchDeck}
								fetchAceVideos={fetchAceVideos}
								fetchVaultVideos={fetchVaultVideos}
								fetchWorker={fetchWorker}
								searchVault={searchVault}
								open={openVideoEditor}
								username={username}
								postForm={postForm}
								toggleError={toggleError}
							/>
						</div>
					</Collapse>
				)}
				{isPowerpoint(documentType) && (
					<Collapse in={openLinkEditor}>
						<div>
							<LinkEditor
								deckIsLoaded={deckIsLoaded}
								deckIsInvalid={deckIsInvalid}
								images={images}
								isDam={isDam}
								slides={slides}
								damJson={damJson}
								vaultId={vaultId}
								useExperimentalImageMatcher={useExperimentalImageMatcher}
								fetchDeck={fetchDeck}
								open={openLinkEditor}
								toggleError={toggleError}
								username={username}
							/>
						</div>
					</Collapse>
				)}
				{isPowerpoint(documentType) && (
					<Button
						variant='primary'
						size='lg'
						block
						disabled={openLinkEditor}
						onClick={toggleVideoEditor}
					>
						Edit Videos
					</Button>
				)}
				{isPowerpoint(documentType) && (
					<Button
						variant='primary'
						size='lg'
						block
						disabled={openVideoEditor}
						onClick={toggleLinksEditor}
					>
						Edit Links
					</Button>
				)}
				{isAudio(documentType) && (
					<h1>Uploaded successfully, press "publish" to complete.</h1>
				)}
				<JsonViewer
					isDam={isDam}
					damJson={damJson}
					slides={slides}
					manuallyUpdateJson={this.manuallyUpdateJson}
				/>
				<ResultModal
					preview={true}
					exposure={exposure}
					error={error}
					show={showResultModal}
					vaultId={vaultId}
					successMsg={`${vaultId} was successfully published`}
					errMsg={`There was an error publishing ${vaultId}: ${error}`}
					handleClose={this.toggleResultModal}
				/>
			</>
		);
	}
}

export default Finalize;
