import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Hotkeys from 'react-hot-keys';
import clone from 'lodash/clone';
import get from 'lodash/get';

import Carousel from '../editor/Carousel';
import HelpModal from '../help/videoEditorHelp';
import ResultModal from '../shared/ResultModal';
import UploaderModal from '../shared/UploaderModal';
import ChooseVideoModal from '../editor/ChooseVideoModal';
import OverwriteModal from '../editor/OverwriteModal';
import EditorButtonGroup from '../editor/EditorButtonGroup';
import EditorSlideSelector from '../editor/EditorSlideSelector';

import { state, initialize } from '../editor/helpers/videos';

class DeckVideoEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...clone(state),
			deck: this.props.vaultId,
			slides: this.props.slides || [],
			backup: this.props.useExperimentalImageMatcher
				? 'expIndexJson'
				: 'sqlIndexJson',
			isShiftPressed: false,
		};
		this.image;
		this.interval;

		this.initialize(this);
	}

	initialize = initialize.bind(this);

	componentDidUpdate(prevProps) {
		const { open, slides } = this.props;

		if (slides !== prevProps.slides) {
			this.setState({ slides });
		} else if (open !== prevProps.open && !open) {
			window.onresize = null;
		} else if (open !== prevProps.open && open) {
			window.onresize = this.setScale;
			setTimeout(this.setScale, 100);
		}
	}

	componentDidMount() {
		if (typeof window.onresize === 'function') {
			window.onresize = null;
		}

		window.onresize = this.setScale;
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	}

	componentWillUnmount() {
		window.onresize = null;
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
	}

	handleKeyDown = (event) => {
		if (event.key === 'Shift') {
			this.setState((prevState) => ({
				isShiftPressed: true,
				crop: { ...prevState.crop, aspect: prevState.aspect },
			}));
		}
	};

	handleKeyUp = (event) => {
		if (event.key === 'Shift') {
			this.setState((prevState) => ({
				isShiftPressed: false,
				crop: { ...prevState.crop, aspect: null },
			}));
		}
	};

	setAspectRatio = (aspect) => this.setState({ aspect });

	toggleHelpModal = () =>
		this.setState({ showHelpModal: !this.state.showHelpModal });

	toggleUploadModal = () =>
		this.setState({ showChooseVideoModal: !this.state.showChooseVideoModal });

	toggleResultModal = () =>
		this.setState({ showResultModal: !this.state.showResultModal });

	toggleOverwriteModal = () =>
		this.setState({ showOverwriteModal: !this.state.showOverwriteModal });

	render() {
		const {
			aceVideos,
			vaultVideos,
			username,
			images,
			progress,
			deckIsLoaded,
			deckIsInvalid,
		} = this.props;

		const {
			crop,
			deck,
			error,
			file,
			showHelpModal,
			showUploadModal,
			showChooseVideoModal,
			showOverwriteModal,
			selectedSlide,
			slides,
			arrayNumber,
			upload,
			worker,
		} = this.state;

		const slide = slides[selectedSlide] || {};
		const hasVideo = !!slide.video;

		const hasRect =
			get(slide, ['video', arrayNumber, 'path']) &&
			get(slide, ['video', arrayNumber, 'rect']);

		return (
			<>
				{error && (
					<Alert
						variant='danger'
						dismissible
						onClose={() => this.setState({ error: false })}
					>
						{error || 'There was an error, check the console'}
					</Alert>
				)}
				<Form className='px-2 py-2'>
					<Form.Row className='d-flex'>
						<Carousel
							setAspectRatio={this.setAspectRatio}
							arrayNumber={arrayNumber}
							crop={crop}
							deck={deck}
							deckIsLoaded={deckIsLoaded}
							deckIsInvalid={deckIsInvalid}
							hasObject={hasVideo}
							images={images}
							slide={slide}
							slides={slides}
							selectedSlide={selectedSlide}
							showChooseVideoModal={showChooseVideoModal}
							changeSelectedObject={this.changeVideoNumber}
							onCropChange={this.onCropChange}
							onImageLoaded={this.onImageLoaded}
							onLoadedVideoData={this.onLoadedVideoData}
							nextSlide={() => {
								const nextSlide = selectedSlide + 1;
								if (nextSlide < slides.length) {
									const event = {
										currentTarget: { value: (nextSlide + 1).toString() },
									};
									this.changeSlide(event);
								}
							}}
							prevSlide={() => {
								const nextSlide = selectedSlide - 1;
								if (!(nextSlide < 0)) {
									const event = {
										currentTarget: { value: (nextSlide + 1).toString() },
									};
									this.changeSlide(event);
								}
							}}
							property={this.state.property}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Video path</Form.Label>
							<Form.Control
								type='text'
								readOnly
								placeholder={get(slide, ['video', arrayNumber, 'path'])}
							/>
						</Form.Group>
						<EditorSlideSelector
							deckIsLoaded={deckIsLoaded}
							property={this.state.property}
							changeSlide={this.changeSlide}
							slides={slides}
							selectedSlide={selectedSlide}
						/>
					</Form.Row>
					<EditorButtonGroup
						property={this.state.property}
						togglePropertyModal={this.toggleVideoModal}
						clear={this.clear}
						deckIsLoaded={deckIsLoaded}
						deckIsInvalid={deckIsInvalid}
						hasRect={hasRect}
					/>
				</Form>
				<hr />
				<HelpModal show={showHelpModal} handleClose={this.toggleHelpModal} />
				<UploaderModal show={showUploadModal} worker={worker} />
				<OverwriteModal
					show={showOverwriteModal}
					file={file}
					handleClose={this.toggleOverwriteModal}
					uploadVideo={this.uploadVideo}
				/>
				<ChooseVideoModal
					file={file}
					show={showChooseVideoModal}
					arrayNumber={arrayNumber}
					hasVideo={hasVideo}
					deckIsLoaded={deckIsLoaded}
					slides={slides}
					selectedSlide={selectedSlide}
					videos={upload === 1 ? vaultVideos : aceVideos}
					upload={upload}
					username={username}
					progress={progress}
					oldVals={this.state.oldVals}
					clearVideo={this.clear}
					downloadVideo={this.downloadVideo}
					uploadVideo={this.props.uploadVideo}
					setVaultVideo={this.setVaultVideo}
					setUpload={(value) => this.setState({ upload: value })}
					onPathChange={this.onPathChange}
					onFileChange={this.onFileChange}
					handleClose={this.toggleUploadModal}
					restoreOldVals={this.restoreOldVals}
				/>
				<ResultModal
					show={false}
					vaultId={deck}
					error={error}
					successMsg={`${deck} was successly updated`}
					errMsg={`There was an error updating ${deck}`}
					handleClose={this.toggleResultModal}
				/>
			</>
		);
	}
}

export default DeckVideoEditor;
