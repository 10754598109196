import { createTypes, async } from 'redux-action-creator';

export default createTypes([
  'GET_DARKMODE',
  'TOGGLE_DARKMODE',
  'LOGOUT',
  'UPDATE_PROGRESS',
  'UPDATE_FORM',
  'UPDATE_LOGIN_FORM',
  'UPDATE_SESSION_ID',
  'GET_SESSION_ID',
  ...async('CHECK_ACE_DECK_IS_WIDESCREEN'),
  ...async('CHECK_VAULT_DECK_IS_WIDESCREEN'),
  ...async('FETCH_CATEGORIES_INTERNAL'),
	...async('FETCH_CATEGORIES_EXTERNAL'),
	...async('FETCH_CATEGORIES_COMBINED'),
  ...async('FETCH_LOGS'),
  ...async('FETCH_DECK_BACKUPS'),
  ...async('FETCH_ROUTES'),
  ...async('CHECK_IF_VIDEO_EXISTS'),
  ...async('TOKENIZE'),
  ...async('FETCH_STAGED_INDEX_JSON'),
  ...async('FETCH_ACE_LINK_THUMBNAIL_PREVIEW'),
  ...async('PUBLISH_ACE_LINK_THUMBNAIL'),
  ...async('FETCH_VAULT_LINKS'),
  ...async('CLEAR_VAULT_LINK_THUMBNAIL'),
  ...async('STAGE_VAULT_LINK_THUMBNAIL'),
  ...async('FETCH_VAULT_LINK_THUMBNAIL'),
  ...async('DOWNLOAD_VAULT_LINK_THUMBNAIL'),
  ...async('UPLOAD_VAULT_LINK_THUMBNAIL'),
  ...async('AUTHORIZE_USER'),
  ...async('FETCH_LATEST_COMMIT_SHA'),
  ...async('POST_FORM'),
  ...async('FETCH_CATEGORIES'),
  ...async('FETCH_CATEGORIES_DEREFERENCE'),
  ...async('UPDATE_CATEGORIES_DEREFERENCE'),
  ...async('FETCH_COLLECTIONS'),
  ...async('FETCH_COLLECTIONS_DEREFERENCE'),
  ...async('UPDATE_COLLECTIONS_DEREFERENCE'),
  ...async('FETCH_CONTENT'),
  ...async('REMOVE_CONTENT'),
  ...async('FETCH_DAMS'),
  ...async('REMOVE_DAMS'),
  ...async('FETCH_DECK'),
  ...async('FETCH_DECK_INDEX'),
  ...async('FETCH_LOCALS'),
  ...async('DOWNLOAD_VAULT_DOCUMENT_RENDITION'),
  ...async('DOWNLOAD_VAULT_DOCUMENT_SOURCE'),
  ...async('DOWNLOAD_VAULT_DOCUMENT_VIDEO'),
  ...async('DOWNLOAD_VAULT_DOCUMENT_THUMBNAIL'),
  ...async('DOWNLOAD_VAULT_DOCUMENT_AUDIO'),
  ...async('FETCH_VAULT_COUNTRY_CODES'),
  ...async('FETCH_VAULT_DECK'),
  ...async('FETCH_VAULT_ALL_DOCUMENTS'),
  ...async('FETCH_VAULT_ALL_DECKS'),
  ...async('FETCH_VAULT_ALL_DAMS'),
  ...async('FETCH_VAULT_ALL_PDFS'),
  ...async('FETCH_VAULT_ALL_VIDEOS'),
  ...async('FETCH_VAULT_ALL_PODCASTS'),
  ...async('FETCH_PODCAST_THUMBNAIL'),
  ...async('FETCH_VAULT_USER'),
  ...async('FETCH_VAULT_NEW_CATEGORIES'),
  ...async('FETCH_VIDEOS'),
  ...async('FETCH_WORKER'),
  ...async('FETCH_LAST_WORKER'),
  ...async('FETCH_WORKERS'),
  ...async('CLEAR_WORKER'),
  ...async('RESTART_WORKER'),
  ...async('CLEAN_WORKERS'),
  ...async('CLEAR_ALL_FINISHED_WORKERS'),
  ...async('CLEAR_ALL_FAILED_WORKERS'),
  ...async('FETCH_PDF_THUMBNAIL'),
  ...async('LOGIN'),
  ...async('SEARCH_VAULT'),
  ...async('UPDATE_DECK_DATA'),
  ...async('UPLOAD_PDF_THUMBNAIL'),
  ...async('UPLOAD_VIDEO'),
  ...async('PUBLISH'),
  ...async('VALIDATE_SESSION'),
  ...async('FETCH_TOKENIZER_WORKER'),
  ...async('FETCH_TOKENIZER_WORKERS'),
  ...async('KILL_TOKENIZER_WORKER'),
  ...async('RESTART_TOKENIZER_WORKER'),
  ...async('CLEAN_TOKENIZER_WORKERS'),
  ...async('DEREFERENCE_RELATIONSHIPS')
]);
