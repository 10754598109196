import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const ContentZoneSelector = ({ disabled, onChange, selected, readOnly }) => (
	<Form.Group as={Col} controlId='contentZone'>
		<Form.Label>Content Zone</Form.Label>
		<Form.Control
			custom
			name='contentZone'
			as='select'
			onChange={onChange}
			value={selected}
			disabled={disabled}
			readOnly={readOnly}
		>
			<option>US</option>
			<option>EU</option>
			<option>ME</option>
			<option>APAC</option>
			<option>JPN</option>
			<option>LAT</option>
		</Form.Control>
	</Form.Group>
);

ContentZoneSelector.defaultProps = {
	disabled: false,
	readOnly: false
};

export default ContentZoneSelector;
