import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const ChooseVideoModal = ({
	show,
	deckIsLoaded,
	hasUrls,
	url,
	handleClose,
	onChange,
	restoreOldVals,
}) => (
	<Modal show={show}>
		<Modal.Header>
			<Modal.Title>{hasUrls ? 'Edit Link' : 'Add Link'}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>Target</Form.Label>
						<Form.Control
							name='url'
							type='text'
							placeholder='Url, Slide Number or Vault number, e.g, IMP-1234'
							value={url}
							onChange={onChange}
							disabled={!deckIsLoaded}
						/>
					</Form.Group>
				</Form.Row>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button
				variant='secondary'
				onClick={() => {
					restoreOldVals();
					handleClose();
				}}
			>
				Cancel
			</Button>
			<Button variant='primary' onClick={handleClose}>
				Select
			</Button>
		</Modal.Footer>
	</Modal>
);

export default ChooseVideoModal;
