import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import compact from 'lodash/compact';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import DeckTypeahead from '../uploader/DeckTypeahead';
import RemoverConfirmModal from './RemoverConfirmModal';
import ResultModal from '../shared/ResultModal';

import { handleError } from '../uploader/helpers';

import { fetchContent, removeContent, removeDams } from '../../redux/actions';

const DeckRemover = () => {
	const dispatch = useDispatchRequest();
	const username = useSelector((state) => state.login.username);
	const { data: content } = useQuery({
		type: 'FETCH_CONTENT',
		action: fetchContent,
		autoLoad: true,
	});
	const decks = flow(
		flatten,
		uniq,
		compact,
		(arr) => arr.map((o) => o.vaultId),
		(arr) => arr.sort((a, b) => a.localeCompare(b))
	)(content);

	const [done, setDone] = useState(false);
	const [show, setShow] = useState(false);
	const [vaultId, setVaultId] = useState('');
	const [oldVaultId, setOldVaultId] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [notify, setNotify] = useState(true);

	useEffect(() => {
		if (done) {
			setVaultId('');
		}
	}, [done]);

	const onChange = ([value]) => {
		if (!value) return;
		setVaultId(value);
	};

	const toggleModal = () => {
		setShow(!show);
	};

	const toggleResultModal = () => {
		setDone(!done);
	};

	const remove = async () => {
		setError(false);
		setLoading(true);
		setDone(false);

		try {
			const entry = find(content, { vaultId });
			if (entry) {
				if (vaultId.toLowerCase().includes('dam')) {
					await dispatch(removeDams(entry, username, notify));
				} else {
					await dispatch(removeContent(entry, username));
				}

				setOldVaultId(vaultId);
				setDone(true);
				setLoading(false);
				toggleModal();
			} else {
				throw Error(`Could not find ${vaultId} in content array`);
			}
		} catch (err) {
			console.error(err);
			setDone(false);
			setLoading(false);
			setError(handleError(err));
			toggleModal();
		}
	};

	return (
		<Container>
			{error && (
				<Alert variant='danger' dismissible onClose={() => setError(false)}>
					{error}
				</Alert>
			)}
			<Form>
				<Form.Row>
					<h1>Remove Deck</h1>
				</Form.Row>
				<Form.Row>
					<DeckTypeahead
						decks={decks}
						selected={vaultId}
						onChange={onChange}
						// onRemove={toggleModal}
						loading={loading}
					/>
				</Form.Row>
				<Form.Row>
          <Form.Group as={Col}>
            <Button variant='danger' block onClick={toggleModal} disabled={isEmpty(vaultId)}>
              Remove
            </Button>
          </Form.Group>
				</Form.Row>
			</Form>
			<RemoverConfirmModal
				show={show}
				vaultId={vaultId}
				handleRemove={remove}
				handleClose={toggleModal}
			/>
			<ResultModal
				show={done}
				vaultId={oldVaultId}
				error={error}
				preview={false}
				successMsg={`${oldVaultId} was successfully removed`}
				errMsg={`There was an error removing ${oldVaultId}`}
				handleClose={toggleResultModal}
			/>
		</Container>
	);
};

export default DeckRemover;
