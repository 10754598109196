import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';
import isNull from 'lodash/isNull';
import replace from 'lodash/replace';

import StandaloneAlerts from './Alerts';
import CountrySelector from './CountrySelector';
import ContentZoneSelector from './ContentZoneSelector';
import DeckTypeahead from './DeckTypeahead';
import LanguageSelector from './LanguageSelector';

import {
	handleError,
	printCategory,
	populateFormWithVaultData,
	populatePermissionsAndStatus,
	onChange,
} from './helpers';

import missingImage from '../../../public/images/missing_1730_1.png';

class PodcastUploader extends React.Component {
	state = {
		loading: false,
		error: false,
		done: false,
		isDam: false,
		audioLoading: false,
		mode: 'deploy',
		deckExists: false,
		pdfThumbnail: null,
	};

	onChange = (event) => onChange.bind(this)(event);

	onVaultIdChange = async ([value]) => {
		if (!value) return;

		const { downloadAudio, updateForm } = this.props;
		const { mode } = this.state;

		updateForm({
			vaultId: value.document_number__v,
			audio: null,
			jobId: uuidv4(),
			departments: value.department__c,
			conditions: value.condition__c
		});

		try {
			this.setState(
				{
					isDam: value.document_number__v.toLowerCase().includes('dam'),
					isIbr: value.document_number__v.toLowerCase().includes('ibr'),
				},
				async () => {
					// populate fields

					const {
						id,
						filename__v: fileName,
					} = await this.populateFormWithVaultData(value.document_number__v);

					await this.populatePermissionsAndStatus(
						'POD',
						value.document_number__v
					);

					if (mode === 'deploy') {
						this.setState({ audioLoading: true });

						await downloadAudio(id);

						this.setState({ audioLoading: false });

						updateForm('audio', new File([], fileName, { type: 'audio/mp3' }));
					} else {
						updateForm('audio', null);
					}
				}
			);
		} catch (err) {
			this.setState({ error: handleError(err) });
		}
	};

	populateFormWithVaultData = populateFormWithVaultData.bind(this);

	populatePermissionsAndStatus = populatePermissionsAndStatus.bind(this);

	submitForm = async () => {
		const { form, postForm, fetchContent, updateDeckData } = this.props;
		const { mode } = this.state;

		const hasAudio = !isNull(form.audio);

		const formData = new FormData();
		formData.append('documentId', form.documentId);
		formData.append('mainCategory', form.mainCategory);
		formData.append('secondCategory', form.secondCategory);
		formData.append('keywords', form.keywords);
		formData.append('languageCode', form.languageCode);
		formData.append('contentZone', form.contentZone);
		formData.append('countryCodes', form.countryCodes.toString());
		formData.append('permissions', form.permissions);
		formData.append('qpa', form.qpa);
		formData.append('status', form.status);
		formData.append('vaultId', form.vaultId);
		formData.append('title', replace(form.title, '/', '-'));
		formData.append('username', this.props.username);
		formData.append('operation', form.operation);
		formData.append('notify', form.notify.toString());
		formData.append('contentType', 'POD');
		formData.append('exposure', form.exposure);
		formData.append('relatedItems', form.relatedItems);
		formData.append('descriptor', form.aceProDescriptor);
		formData.append('jobId', form.jobId);
		formData.append('departments', form.departments ? form.departments.join(',') : '');
		formData.append('conditions', form.conditions ? form.conditions.join(',') : '');		

		if (mode === 'deploy' && hasAudio) {
			formData.append('audio', form.audio);
		}

		try {
			if (mode === 'deploy' && hasAudio) {
				this.setState({ loading: true });

				await postForm(formData);

				this.setState({
					done: true,
					loading: false,
				});
			} else {
				await updateDeckData(formData);

				this.setState(
					{
						loading: false,
						done: true,
					},
					() => fetchContent('POD')
				);
			}
		} catch (err) {
			this.setState({
				loading: false,
				error: handleError(err),
			});
		}
	};

	printCategory = printCategory.bind(this);

	render() {
		const {
			done,
			deckExists,
			error,
			loading,
			warn,
			audioLoading,
			mode,
			pdfThumbnail,
		} = this.state;

		const {
			podcasts,
			fileType,
			locals,
			countries,
			form: {
				permissions,
				contentZone,
				countryCodes,
				language,
				title,
				keywords,
				vaultId,
				audio,
				internalMainCategory,
				internalSecondCategory,
				internalThirdCategory,
				externalMainCategory,
				externalSecondCategory,
				externalThirdCategory,
			},
			updateForm,
		} = this.props;

		return (
			<>
				<Container>
					<StandaloneAlerts
						done={done}
						error={error}
						warn={warn}
						file={fileType}
						mode={mode}
						setError={(error) => this.setState({ error })}
						setDone={(done) => this.setState({ done })}
						setWarn={(warn) => this.setState({ warn })}
					/>
					<Form encType='multipart/form-data'>
						<Form.Row>
							<h1>Upload Podcast</h1>
						</Form.Row>
						<Form.Row>
							<DeckTypeahead
								accept='.mp3'
								download={audio}
								decks={podcasts}
								onChange={this.onVaultIdChange}
								labelKey='document_number__v'
								selected={vaultId}
								loading={audioLoading}
								deckExists={deckExists}
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='title'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									placeholder='Enter title'
									name='title'
									onChange={this.onChange}
									value={title}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='keywords'>
								<Form.Label>Keywords</Form.Label>
								<Form.Control
									placeholder='Enter comma seperated keywords'
									name='keywords'
									onChange={this.onChange}
									value={keywords}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='internalCategories'>
								<Form.Label>Internal Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										internalMainCategory,
										internalSecondCategory,
										internalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='externalCategories'>
								<Form.Label>External Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										externalMainCategory,
										externalSecondCategory,
										externalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<LanguageSelector 
								locals={locals}
								onChange={this.onChange} 
								selected={language}
							/>
							<ContentZoneSelector
								onChange={this.onChange}
								selected={contentZone}
							/>
						</Form.Row>
						<Form.Row>
							<CountrySelector
								countries={countries}
								onChange={this.onChange}
								selected={countryCodes}
								
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='thumbnail'>
								<Card className='justify-content-center'>
									<Card.Header>Preview</Card.Header>
									<Card.Body className='d-flex justify-content-center'>
										<img
											alt='crop'
											width='310px'
											height='213.4px'
											src={pdfThumbnail || missingImage}
										/>
									</Card.Body>
								</Card>
							</Form.Group>
						</Form.Row>
						<Form.Group controlId='permissions'>
							<Form.Check
								custom
								name='permissions'
								type='checkbox'
								label='Restricted?'
								checked={permissions === 'R'}
								onChange={({ currentTarget: { checked } }) =>
									updateForm('permissions', checked ? 'R' : 'pub')
								}
							/>
						</Form.Group>
						<Button variant='primary' onClick={this.submitForm} size='lg' block>
							{loading && (
								<Spinner
									as='span'
									animation='grow'
									role='status'
									aria-hidden='true'
									size='sm'
								/>
							)}
							{mode === 'deploy' ? 'Upload' : 'Update'}
						</Button>
					</Form>
				</Container>
			</>
		);
	}
}

export default PodcastUploader;
