import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const DeckUploaderHelp = ({ show, handleClose }) => {
  const [url, setUrl] = useState();

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Steps to add link overlays:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ol>
              <li>Under the <code>deck</code> field, type the name of the deck you want to add links to</li>
              <li>Once the slides have loaded, select the target slide unde the <code>slide</code> field</li>
              <li>Click and drag over the slide preview to set the link overlay coordinates</li>
              <li>Under the <code>link</code> field, enter the name of the url</li>
              <li>Use the <code>link number</code> input to add additional links to the slide</li>
              <li>You can preview the deck's json in the json viewer below the form</li>
              <li><code>Clear Slide</code> clears all link data for the current slide, you can use this to delete stray link data</li>
              <li><code>Clear Selected</code> clears the link data for the selected link number</li>
              <li>Click <code>update</code> to upload the link data</li>
            </ol>
          </Col>
          <Col>
            <img className="img-fluid" src={url || require('../../../public/images/missing_1730_1.png')} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" block onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeckUploaderHelp;
