import axios from 'axios';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ReactCrop from 'react-image-crop';
import { pdfjs } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';
import isNull from 'lodash/isNull';

import StandaloneAlerts from './Alerts';
import CountrySelector from './CountrySelector';
import ContentZoneSelector from './ContentZoneSelector';
import DeckTypeahead from './DeckTypeahead';
import FileInput from '../editor/FileInputWithUpload';
import LanguageSelector from './LanguageSelector';

import HelpModal from '../help/pdfUploaderHelp';

import {
	handleError,
	printCategory,
	populateFormWithVaultData,
	populatePermissionsAndStatus,
	onChange,
	base64ArrayBuffer,
} from './helpers';

import missingImage from '../../../public/images/missing_1730_1.png';

class PdfUploader extends React.Component {
	state = {
		croppedImageUrl: '',
		documentUrl: '',
		done: false,
		previewLoading: false,
		loading: false,
		error: false,
		imageScale: {
			scaleX: 0,
			scaleY: 0,
		},
		crop: {
			height: 0,
			width: 0,
			x: 0,
			y: 0,
			unit: '%',
		},
		warn: false,
		showHelp: false,
		mode: 'deploy',
		deckExists: false,
		toggleCreateThumbnail: false,
		pdfThumbnail: null,
	};

	fileUrl;
	imageRef;

	componentDidMount() {
		pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}

	onChange = (event) => onChange.bind(this)(event);

	convert = async (file) => {
		try {
			const arrayBuffer = await new Response(file).arrayBuffer();
			const pdf = await pdfjs.getDocument(arrayBuffer).promise;
			const pdfPage = await pdf.getPage(1);
			// Display page on the existing canvas with 100% scale.
			const viewport = pdfPage.getViewport({ scale: 1 });

			const canvas = document.createElement('canvas');
			canvas.width = viewport.width;
			canvas.height = viewport.height;

			const ctx = canvas.getContext('2d');

			await pdfPage.render({
				canvasContext: ctx,
				viewport: viewport,
			}).promise;

			this.setState({ documentUrl: canvas.toDataURL('image/png', 1.0) });
		} catch (err) {
			this.setState({ error: handleError(err) });
		}
	};

	onVaultIdChange = async ([value]) => {
		if (!value) return;

		const { downloadRendition, updateForm } = this.props;
		const { mode } = this.state;
		const { document_number__v: vaultId } = value;

		try {
			updateForm('vaultId', vaultId);
			updateForm('jobId', uuidv4());
			updateForm('departments', value.department__c);
			updateForm('conditions', value.condition__c);

			// populate fields
			const { id } = await this.populateFormWithVaultData(vaultId);
			await this.populatePermissionsAndStatus('PDF', vaultId);

			if (mode === 'deploy') {
				// download document attachment
				this.setState({ previewLoading: true });

				const { data: rendition } = await downloadRendition(id);

				updateForm('pdf', rendition);

				await this.convert(rendition);

				this.setState({ previewLoading: false });
			} else {
				updateForm('pdf', null);
			}
		} catch (err) {
			this.setState({
				error: handleError(err),
				previewLoading: false,
			});
		}
	};

	populateFormWithVaultData = populateFormWithVaultData.bind(this);

	populatePermissionsAndStatus = populatePermissionsAndStatus.bind(this);

	onImageLoaded = (image) => (this.imageRef = image);

	onCropComplete = (crop) => this.makeClientCrop(crop);

	onCropChange = (crop) => this.setState({ crop });

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'thumbnail.png'
			);
			this.setState({ pdfThumbnail: croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;

		this.setState({
			imageScale: {
				scaleX,
				scaleY,
			},
		});

		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return this.canvasToBlob(canvas, fileName);
	}

	canvasToBlob = (canvas, fileName) =>
		new Promise((resolve) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('canvas is empty');
					return;
				}
				blob.name = fileName;
				// URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, 'image/png');
		});

	toggleHelpModal = () => {
		this.setState({ showHelp: !this.state.showHelp });
	};

	uploadCustomThumbnail = async ({ currentTarget: { files } }) => {
		const { uploadPdfThumbnail, updateForm, form } = this.props;
		const { vaultId } = form;

		try {
			if (files && files.length > 0) {
				const file = files[0];
				const formData = new FormData();
				formData.append('vaultId', vaultId);
				formData.append('status', status);
				formData.append('image', file);

				this.setState({ loading: true });

				const { data: image } = await uploadPdfThumbnail(vaultId, formData);

				this.setState({
					pdfThumbnail: base64ArrayBuffer(image),
					loading: false,
				});

				updateForm('image', image);
			}
		} catch (err) {
			console.error(err);

			this.setState({
				loading: false,
				error: handleError(err),
			});
		}
	};

	submitForm = async () => {
		const { form, fetchContent, postForm, updateDeckData } = this.props;
		const { documentUrl, mode } = this.state;

		try {
			const hasPdf = !isNull(form.pdf);

			const formData = new FormData();
			formData.append('documentId', form.documentId);
			formData.append('contentZone', form.contentZone);
			formData.append('countryCodes', form.countryCodes.toString());
			formData.append('keywords', form.keywords);
			formData.append('mainCategory', form.mainCategory);
			formData.append('secondCategory', form.secondCategory);
			formData.append('status', form.status);
			formData.append('permissions', form.permissions);
			formData.append('title', form.title);
			formData.append('languageCode', form.languageCode);
			formData.append('username', this.props.username);
			formData.append('vaultId', form.vaultId);
			formData.append('operation', form.operation);
			formData.append('contentType', 'PDF');
			formData.append('exposure', form.exposure);
			formData.append('relatedItems', form.relatedItems);
			formData.append('descriptor', form.aceProDescriptor);
			formData.append('jobId', form.jobId);
			formData.append('departments', form.departments ? form.departments.join(',') : '');
			formData.append('conditions', form.conditions ? form.conditions.join(',') : '');			

			if (mode === 'deploy' && hasPdf) {
				if (form.image) {
					formData.append(
						'image',
						new File(
							[base64ArrayBuffer(form.image)],
							`${form.vaultId}-thumbnail.png`,
							{ type: 'image/png' }
						)
					);
				} else {
					const { data } = await axios.get(documentUrl, {
						responseType: 'blob',
					});
					formData.append(
						'image',
						new File([data], `${form.vaultId}-thumbnail.png`, {
							type: 'image/png',
						})
					);
					formData.append('imageScale', JSON.stringify(this.state.imageScale));
					formData.append('crop', JSON.stringify(this.state.crop));
				}

				this.setState({ loading: true });

				await postForm(formData);

				this.setState({
					done: true,
					loading: false,
				});
			} else {
				await updateDeckData(formData);

				this.setState(
					{
						loading: false,
						done: true,
					},
					() => fetchContent('PDF')
				);
			}
		} catch (err) {
			console.error(err);

			this.setState({
				loading: false,
				error: handleError(err),
			});
		}
	};

	printCategory = printCategory.bind(this);

	render() {
		const {
			crop,
			croppedImageUrl,
			documentUrl,
			loading,
			previewLoading,
			error,
			done,
			warn,
			showHelp,
			mode,
			deckExists,
			pdfThumbnail,
			toggleCreateThumbnail,
		} = this.state;

		const {
			countries,
			locals,
			pdfs,
			fileType,
			form: {
				vaultId,
				permissions,
				countryCodes,
				contentZone,
				title,
				mainCategory,
				secondCategory,
				language,
				keywords,
				qpa,
				pdf,
				internalMainCategory,
				internalSecondCategory,
				internalThirdCategory,
				externalMainCategory,
				externalSecondCategory,
				externalThirdCategory,
			},
			updateForm,
		} = this.props;

		const hasThumbnail = !!pdfThumbnail;

		return (
			<>
				<Container>
					<StandaloneAlerts
						done={done}
						error={error}
						warn={warn}
						file={fileType}
						mode={mode}
						setError={(error) => this.setState({ error })}
						setDone={(done) => this.setState({ done })}
						setWarn={(warn) => this.setState({ warn })}
					/>
					<Form method='post' encType='multipart/form-data'>
						<Form.Row>
							<h1>Upload PDF</h1>
							<p onClick={this.toggleHelpModal} style={{ ':hover': 'pointer' }}>
								ⓘ
							</p>
						</Form.Row>
						<Form.Row>
							<DeckTypeahead
								decks={pdfs}
								onChange={this.onVaultIdChange}
								labelKey='document_number__v'
								selected={vaultId}
								loading={previewLoading}
								deckExists={deckExists}
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='title'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									placeholder='Enter title'
									name='title'
									onChange={this.onChange}
									value={title}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='keywords'>
								<Form.Label>Keywords</Form.Label>
								<Form.Control
									placeholder='Enter comma seperated keywords'
									name='keywords'
									onChange={this.onChange}
									value={keywords}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='internalCategories'>
								<Form.Label>Internal Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										internalMainCategory,
										internalSecondCategory,
										internalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='externalCategories'>
								<Form.Label>External Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										externalMainCategory,
										externalSecondCategory,
										externalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<LanguageSelector
								locals={locals}
								onChange={this.onChange} 
								selected={language}

							/>
							<ContentZoneSelector
								onChange={this.onChange}
								selected={contentZone}
							/>
						</Form.Row>
						<Form.Row>
							<CountrySelector
								countries={countries}
								onChange={this.onChange}
								selected={countryCodes}
							/>
						</Form.Row>
						<Form.Group controlId='permissions'>
							<Form.Check
								custom
								name='permissions'
								type='checkbox'
								label='Restricted?'
								checked={permissions === 'R'}
								onChange={({ currentTarget: { checked } }) =>
									updateForm('permissions', checked ? 'R' : 'pub')
								}
							/>
						</Form.Group>
						{mode === 'deploy' && (
							<>
								<Button
									variant='secondary'
									onClick={() =>
										this.setState({
											toggleCreateThumbnail: !toggleCreateThumbnail,
										})
									}
									block
									style={{ marginTop: '5px', marginBottom: '10px' }}
								>
									{mode === 'deploy' && !pdfThumbnail
										? 'Create Thumbnail'
										: 'Update Thumbnail'}
								</Button>
							</>
						)}
						{mode === 'deploy' && (
							<Collapse in={toggleCreateThumbnail}>
								<div>
									<Form.Row>
										<Form.Group as={Col} controlId='image'>
											<Form.Label>Upload Custom Thumbnail</Form.Label>
											<FileInput
												name='thumbnail'
												id='thumbnail'
												accept='image/*'
												onChange={this.uploadCustomThumbnail}
											/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Col md={6}>
											<Card>
												<Card.Header>Original</Card.Header>
												{previewLoading ? (
													<Spinner animation='border' role='status'>
														<span className='sr-only'>Loading...</span>
													</Spinner>
												) : (
													<ReactCrop
														imageStyle={{
															maxWidth: '100%',
															maxHeight: '100%',
														}}
														src={documentUrl || missingImage}
														crop={crop}
														onImageLoaded={this.onImageLoaded}
														onComplete={this.onCropComplete}
														onChange={this.onCropChange}
													/>
												)}
											</Card>
										</Col>
										<Col md={6}>
											<Col>
												<Card className='justify-content-center'>
													<Card.Header>Preview</Card.Header>
													<Card.Body className='d-flex justify-content-center'>
														<img
															alt='crop'
															width='310px'
															height='213.4px'
															src={pdfThumbnail || missingImage}
														/>
													</Card.Body>
													<Card.Footer>
														Note: image will appear in higher resolution in ACE
														Pro
													</Card.Footer>
												</Card>
											</Col>
										</Col>
									</Form.Row>
								</div>
							</Collapse>
						)}
						<Button
							variant='primary'
							onClick={this.submitForm}
							disabled={
								!vaultId || (mode === 'deploy' && (!pdf || !hasThumbnail))
							}
							size='lg'
							block
							style={{ marginTop: '5px' }}
						>
							{loading && (
								<Spinner
									as='span'
									animation='grow'
									role='status'
									aria-hidden='true'
									size='sm'
								/>
							)}
							{mode === 'deploy' ? 'Upload' : 'Update'}
						</Button>
					</Form>
				</Container>
				<HelpModal show={showHelp} handleClose={this.toggleHelpModal} />
			</>
		);
	}
}

export default PdfUploader;
