import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import capitalize from 'lodash/capitalize';

const EditorButtonGroup = ({
	property,
	togglePropertyModal,
	clear,
	deckIsLoaded,
	deckIsInvalid,
	hasRect,
}) => (
	<Form.Row className='pt-2'>
		<Form.Group as={Col}>
			<Button
				onClick={() => togglePropertyModal(null, false)}
				disabled={!deckIsLoaded || deckIsInvalid}
				block
				variant='secondary'
			>
				Add {capitalize(property)}
			</Button>
			<Button
				onClick={() => togglePropertyModal(null, true)}
				disabled={!hasRect}
				block
				variant='secondary'
			>
				Edit {capitalize(property)}
			</Button>
			<Button onClick={clear} disabled={!hasRect} block variant='secondary'>
				Remove {capitalize(property)}
			</Button>
		</Form.Group>
	</Form.Row>
);

export default EditorButtonGroup;
