import types from '../types';

import type { PayloadAction } from '../../typings';

type State = {
  +loaded: number,
  +total: number
};

const initialState = {
  loaded: 0,
  total: 0
};

const progressReducer = (state: State = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PROGRESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export default progressReducer;
