import React, { useEffect } from 'react';
import { useDispatchRequest } from '@redux-requests/react';
import { useQuery } from '@redux-requests/react';
import { useUrlSearchParams } from 'use-url-search-params';
import { useHistory } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';

import { validateSession } from '../redux/actions';

const PrivateWrapper = ({ component: Component, ...props }) => {
	const history = useHistory();
	const dispatch = useDispatchRequest();
	const searchParams = useUrlSearchParams();
	const sessionId = searchParams.sessionId || props.sessionId;
	const { valid, load: validate, loading } = useQuery({ type: 'VALIDATE_SESSION', action: validateSession, autoLoad: false, variables: [sessionId] });

	useEffect(() => {
		if (!isUndefined(valid) && !loading) {
			console.log('Session ID:', sessionId);
			console.log('AUTHENTICATED: ', valid);

			if (valid !== 'valid') {
				history.push('/login');
			}
		}
	}, [valid]);

	return <Component {...props} />;
};

export default PrivateWrapper;
