import React from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';

import Warning from './Warning';


const AspectRatioWarningModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Upgrading Slide Aspect Ratio</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center">
                <Col xs="auto">
                    <Warning />
                </Col>
                <Col>
                    <p>
                        <strong>Warning:</strong> The slide width of this deck is being changed.
                    </p>
                    <p>
                    Video and link placements will be automatically scaled up when you publish. 
                    Changing the placements in the video or link editor before publishing will lead to unwanted results.
                    </p>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AspectRatioWarningModal;