import React from 'react';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import { pdfjs } from 'react-pdf';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import replace from 'lodash/replace';
import stubFalse from 'lodash/stubFalse';
import stubTrue from 'lodash/stubTrue';
import cond from 'lodash/cond';

import AspectRatioWarningModal from '../../shared/AspectRatioWarningModal';
import DeckTypeahead from '../../uploader/DeckTypeahead';
import ContentZoneSelector from '../../uploader/ContentZoneSelector';
import LanguageSelector from '../../uploader/LanguageSelector';
import FileInput from '../../uploader/FileInput';
import UploaderModal from '../../shared/UploaderModal';
import TransitionSelector from '../../uploader/TransitionSelector';

import {
	onChange,
	handleError,
	populateFormWithVaultData,
	populatePermissionsAndStatus,
	printCategory,
	handleResponse
} from '../../uploader/helpers';

import { VIDEO, AUDIO } from '../constants';
import { isPdf, isAudio, isPowerpoint, isVideo } from '../helpers';

import missingImage from '../../../../public/images/missing_1730_1.png';

const TEST =
	process.env.NODE_ENV === 'development' ||
	window.location.href.includes('test');

class VaultUploaderForm extends React.Component<Props, State> {
	state = {
		showModal: false,
		done: false,
		documentType: null,
		download: null,
		error: false,
		isIbr: false,
		isDam: false,
		imageScale: {
			scaleX: 0,
			scaleY: 0,
		},
		loading: false,
		loadingDoc: true,
		show: false,
		test: false,
		workerId: '',
		username: '',
		warn: false,
		worker: {
			progress: 0,
		},
		mode: 'deploy',
		deckExists: false,
		warnAspectRatio: false,
		disableImageMatching: false,
	};

	imageRef;
	interval;

	componentDidMount() {
		pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}

	componentDidUpdate(prevProps) {
		const { uploaded, vaultId, documents } = this.props;

		if (prevProps.vaultId !== vaultId && !isEmpty(vaultId)) {
			this.onVaultIdChange(documents);
		} else if (prevProps.uploaded !== uploaded && !uploaded) {
			this.onVaultIdChange(documents);
		}
	}

	convert = async (file) => {
		try {
			const { setDocumentUrl, setImageBlob } = this.props;

			const arrayBuffer = await new Response(file).arrayBuffer();

			const pdf = await pdfjs.getDocument(arrayBuffer).promise;
			const pdfPage = await pdf.getPage(1);
			const viewport = pdfPage.getViewport({ scale: 1 });

			const canvas = document.createElement('canvas');
			canvas.width = viewport.width;
			canvas.height = viewport.height;

			const ctx = canvas.getContext('2d');

			await pdfPage.render({
				canvasContext: ctx,
				viewport: viewport,
			}).promise;

			const documentUrl = canvas.toDataURL('image/png', 1.0);

			canvas.toBlob((blob) => setImageBlob(blob), 'image/png', 1.0);

			setDocumentUrl(documentUrl);
		} catch (err) {
			console.error(err);
			this.props.toggleAlert('danger', handleError(err));
		}
	};

	handleDocType = async (format, id, fileName) => {
		const {
			downloadSource,
			downloadRendition,
			downloadVideo,
			downloadAudio,
			downloadThumbnail,
			updateForm,
		} = this.props;

		console.log('format: ', format);

		const { mode } = this.state;

		if (isPowerpoint(format)) {
			if (isEqual(mode, 'deploy')) {
				if (isNull(this.state.download)) {
					const { data: source } = await downloadSource(id);

					this.setState({
						loadingDoc: false,
						download: source,
					});
				} else {
					this.setState({ loadingDoc: false });
				}
			}
		} else if (isPdf(format)) {
			const { data: pdf } = await downloadRendition(id);

			updateForm('pdf', pdf);

			await this.convert(pdf);

			this.setState({ loadingDoc: false });
		} else if (isVideo(format)) {
			if (isEqual(mode, 'deploy')) {
				const { data: video } = await downloadVideo(id);
				const { data: thumbnail } = await downloadThumbnail(id);

				updateForm({
					video,
					fileName,
				});

				// this is for displaying crappy vault thumbnails
				const url = URL.createObjectURL(thumbnail);

				this.setState({ loadingDoc: false });
			}
		} else if (isAudio(format)) {
			if (isEqual(mode, 'deploy')) {
				const { data: audio } = await downloadAudio(id);
				updateForm({
					audio,
					fileName,
				});
			}
		}
	};

	onVaultIdChange = ([value]) => {
		if (!value) return;

		console.log('document: ', value);

		this.props.updateForm('vaultId', value.document_number__v);
		this.props.updateForm('jobId', uuidv4());
		this.props.updateForm('departments', value.department__c);
		this.props.updateForm('conditions', value.condition__c);

		this.setState(
			{
				download: null,
				isDam: value.document_number__v.toLowerCase().includes('dam'),
				isIbr: value.document_number__v.toLowerCase().includes('ibr'),
			},
			async () => {
				try {
					const {
						id,
						format__v: format,
						document_number__v: vaultId,
						filename__v: filename,
					} = await this.populateFormWithVaultData(value.document_number__v);

					this.setState({
						loadingDoc: true,
						documentType: format,
					});

					this.populatePermissionsAndStatus(this.getTypeRoute(format), vaultId);

					this.props.setDocumentType(format);

					this.handleDocType(format, id, filename);

					this.setState({ loadingDoc: false });
				} catch (err) {
					console.error(err);
					this.props.toggleAlert('danger', handleError(err));
				}
			}
		);
	};

	getTypeRoute = (documentType) => {
		if (isPowerpoint(documentType)) {
			return 'PRS';
		} else if (isPdf(documentType)) {
			return 'PDF';
		} else if (isVideo(documentType)) {
			return 'VID';
		} else if (isAudio(documentType)) {
			return 'POD';
		}
	};

	onChange = onChange.bind(this);

	populatePermissionsAndStatus = populatePermissionsAndStatus.bind(this);

	populateFormWithVaultData = populateFormWithVaultData.bind(this);

	toggleWarnAspectRatio = () => {
		this.setState({ warnAspectRatio: !this.state.warnAspectRatio });
	};

	submit = async () => {
		const {
			form,
			postForm,
			toggleUploaded,
			setWorkerId,
			updateDeckData,
		} = this.props;
		const { documentType } = this.state;

		const simpleUpdate =
			(isPowerpoint(documentType) && isNull(form.zip)) ||
			(isVideo(documentType) && isNull(form.video)) ||
			(isAudio(documentType) &&
				isNull(form.audio) &&
				form.operation === 'update');

		console.log('simple update?', simpleUpdate);

		try {
			const contentType = this.getTypeRoute(documentType);

			const formData = new FormData();
			formData.append('animated', form.animated);
			formData.append('operation', form.operation);
			formData.append('mainCategory', form.mainCategory);
			formData.append('secondCategory', form.secondCategory);
			formData.append('keywords', form.keywords);
			formData.append('title', replace(form.title, '/', '-'));
			formData.append('languageCode', form.languageCode);
			formData.append('contentZone', form.contentZone);
			formData.append('countryCodes', form.countryCodes.toString());
			formData.append('permissions', form.permissions);
			formData.append('qpa', form.qpa);
			formData.append('status', form.status);
			formData.append('vaultId', form.vaultId);
			formData.append('username', this.props.username);
			formData.append(
				'contentType',
				contentType === 'PRS'
					? form.vaultId.includes('DAM')
						? 'DAM'
						: 'PRS'
					: contentType
			);
			formData.append('documentId', form.documentId);
			formData.append('relatedItems', form.relatedItems);
			formData.append('descriptor', form.aceProDescriptor);
			formData.append('wistiaUrl', form.wistiaUrl);
			formData.append('test', form.test);
			formData.append('jobId', form.jobId);
			formData.append('transition', form.transition);
			formData.append('departments', form.departments ? form.departments.join(',') : '');
			formData.append('conditions', form.conditions ? form.conditions.join(',') : '');
			formData.append('resizeRects', form.resizeRects);

			if (isPdf(documentType)) {
				if (isNull(form.pdf)) {
					throw new Error('no pdf attached');
				}

				formData.append(
					'image',
					new File([], 'image.png', { type: 'image/png' })
				);
				formData.append('temp', 'true');
			} else if (isPowerpoint(documentType)) {
				if (!isNull(form.zip)) {
					console.log('zip: ', form.zip);

					formData.append('zip', form.zip);
					formData.append('temp', 'true');
					formData.append('useAutomaticVideoAndLinkPlacement', form.useAutomaticVideoAndLinkPlacement);
					formData.append('useExperimentalImageMatcher', form.useExperimentalImageMatcher);
				}

				if (form.notify) {
					formData.append('notify', form.notify.toString());
				}

				if (simpleUpdate) {
					this.setState({ loading: true });

					console.log('[PERFORMING SIMPLE UPDATE]', simpleUpdate);

					await updateDeckData(formData);

					this.setState(
						{
							loading: false,
							done: true,
							show: false,
						},
						toggleUploaded
					);
				}
			} else if (isVideo(documentType)) {
				if (!isNull(form.video)) {
					formData.append(
						'video',
						new File([], form.fileName, { type: VIDEO })
					);
					formData.append('embedded', 'false');
					formData.append('temp', 'true');
				}

				if (simpleUpdate) {
					console.log('[PERFORMING SIMPLE UPDATE]', simpleUpdate);

					await updateDeckData(formData);

					this.setState(
						{
							loading: false,
							done: true,
							show: false,
						},
						toggleUploaded
					);
				}
			} else if (isAudio(documentType)) {
				if (!isNull(form.audio)) {
					formData.append(
						'audio',
						new File([], form.fileName, { type: AUDIO })
					);
					formData.append('temp', 'true');
				}

				if (simpleUpdate) {
					console.log('[PERFORMING SIMPLE UPDATE]', simpleUpdate);

					await updateDeckData(formData);

					this.setState(
						{
							loading: false,
							done: true,
							show: false,
						},
						toggleUploaded
					);
				}
			}

			if (simpleUpdate) {
				return;
			}

			this.setState({ loading: true });

			const response = await postForm(formData);

			await handleResponse.call(this, formData, response);
			console.log('response: ', response);

			// there should be a config variable here we can use to determine if we're changing from 1730 to 1920 slides

			console.log('worker id: ', form.jobId);

			setWorkerId(form.jobId);

			this.setState(
				{
					show: true,
					loading: false,
					done: true,
				},
				() =>
				(this.interval = setInterval(
					this.workerInterval.bind(null, form.jobId),
					1000
				))
			);
		} catch (err) {
			console.error(err);
			this.props.toggleAlert('danger', handleError(err));
			this.setState({ loading: false });
		}
	};

	workerInterval = async (workerId) => {
		const { fetchWorker, toggleUploaded } = this.props;

		try {
			const { data: worker } = await fetchWorker(workerId);

			this.setState({ worker }, () => {
				if (Math.round(worker.progress) === 100) {
					clearInterval(this.interval);
					this.setState({ show: false });
					toggleUploaded();
				}
			});
		} catch (err) {
			console.error(err);
			clearInterval(this.interval);
			this.props.toggleAlert('danger', handleError(err));
		}
	};

	printCategory = printCategory.bind(this);

	setOperation = (mode) => {
		const { updateForm } = this.props;
		let operation = 'update';

		if (mode === 'deploy' && !this.state.deckExists) {
			operation = 'create';
		}
		// once it detects its an update its always an update
		this.setState({ mode }, () => updateForm('operation', operation));
	};

	render() {
		const {
			documentType,
			download,
			isDam,
			isIbr,
			loading,
			loadingDoc,
			show,
			worker,
			mode,
			deckExists,
			pdfThumbnail,
			disableImageMatching,
		} = this.state;

		const {
			documents,
			documentUrl,
			locals,
			form: {
				operation,
				useAutomaticVideoAndLinkPlacement,
				useExperimentalImageMatcher,
				grouped,
				animated,
				contentZone,
				countryCodes,
				language,
				permissions,
				title,
				vaultId,
				notify,
				video,
				audio,
				zip,
				mainCategory,
				secondCategory,
				internalMainCategory,
				internalSecondCategory,
				internalThirdCategory,
				externalMainCategory,
				externalSecondCategory,
				externalThirdCategory,
				transition,
				test,
			},
			updateForm,
		} = this.props;

		const disabled = cond([
			[() => loading, stubTrue],
			[() => !isPowerpoint(documentType) && loadingDoc, stubTrue],
			[() => isPowerpoint(documentType) && mode === 'deploy' && !zip, stubTrue],
			[() => isPdf(documentType) && !documentUrl, stubTrue],
			[() => isVideo(documentType) && mode === 'deploy' && !video, stubTrue],
			[() => isAudio(documentType) && mode === 'deploy' && !audio, stubTrue],
			[stubTrue, stubFalse],
		]);

		const internalCategories = this.printCategory(
			internalMainCategory,
			internalSecondCategory,
			internalThirdCategory
		);

		const externalCategories = this.printCategory(
			externalMainCategory,
			externalSecondCategory,
			externalThirdCategory
		);

		const InternalCategories = () => (
			<Form.Row>
				<Form.Group as={Col} controlId='internalCategories'>
					<Form.Label>Internal Categories</Form.Label>
					<Form.Control
						placeholder={loadingDoc ? 'Loading...' : ''}
						name='categories'
						onChange={this.onChange}
						value={internalCategories}
						readOnly
					/>
				</Form.Group>
			</Form.Row>
		);

		const ExternalCategories = () => (
			<Form.Row>
				<Form.Group as={Col} controlId='externalCategories'>
					<Form.Label>External Categories</Form.Label>
					<Form.Control
						placeholder={loading ? 'Loading...' : ''}
						name='categories'
						onChange={this.onChange}
						value={externalCategories}
						readOnly
					/>
				</Form.Group>
			</Form.Row>
		);

		console.log('[DOCUMENTS]: ', documents);

		return (
			<>
				<Breadcrumb className='pt-4'>
					<Breadcrumb.Item>1. Authentication</Breadcrumb.Item>
					<Breadcrumb.Item active>2. Upload</Breadcrumb.Item>
					<Breadcrumb.Item>3. Finalize</Breadcrumb.Item>
					<Button
						variant='outline-secondary'
						disabled={true}
						style={{ margin: '-8px' }}
						className='ml-auto'
					>
						Publish
					</Button>
				</Breadcrumb>
				<Form>
					{(isPowerpoint(documentType) || isVideo(documentType)) && (
						<Form.Group as={Col} controlId='mode'>
							<Form.Label>Operation</Form.Label>
							<ToggleButtonGroup
								name='mode'
								vertical={false}
								style={{ width: '100%' }}
								type='radio'
								value={mode}
							>
								<OverlayTrigger
									placement='bottom'
									overlay={
										<Tooltip id='button-tooltip-1'>
											Uploads new slides, pdf, or video and updates ACE data
										</Tooltip>
									}
								>
									{({ ref, ...triggerHandler }) => (
										<ToggleButton
											{...triggerHandler}
											ref={ref}
											autoComplete='off'
											type='radio'
											name='mode'
											variant='outline-secondary'
											value='deploy'
											checked={mode === 'deploy'}
											active={mode === 'deploy'}
											onClick={() => this.setOperation('deploy')}
										>
											{deckExists
												? 'Update All'
												: isVideo(documentType)
													? 'Create Video'
													: 'Create Deck'}
										</ToggleButton>
									)}
								</OverlayTrigger>
								{deckExists ? (
									<OverlayTrigger
										placement='bottom'
										overlay={
											<Tooltip id='button-tooltip-2'>
												Only updates ACE data, does not require uploading slides
												or corresponding files,
											</Tooltip>
										}
									>
										{({ ref, ...triggerHandler }) => (
											<ToggleButton
												{...triggerHandler}
												ref={ref}
												name='mode'
												type='radio'
												autoComplete='off'
												checked={mode === 'update'}
												variant='outline-secondary'
												value='update'
												active={mode === 'update'}
												disabled={!deckExists}
												onClick={() => this.setOperation('update')}
											>
												Update ACE Data
											</ToggleButton>
										)}
									</OverlayTrigger>
								) : (
									<ToggleButton
										name='mode'
										type='radio'
										checked={mode === 'update'}
										variant='outline-secondary'
										value='update'
										active={mode === 'update'}
										disabled={true}
									>
										Update ACE Data
									</ToggleButton>
								)}
							</ToggleButtonGroup>
						</Form.Group>
					)}
					<DeckTypeahead
						accept={isPowerpoint(documentType) ? '.pptx' : '.mp4'}
						decks={documents}
						onChange={this.onVaultIdChange}
						loading={loadingDoc}
						selected={vaultId}
						download={download}
						labelKey='document_number__v'
						showDownload={isPowerpoint(documentType)}
						disabled={true}
						placeholder='Loading Vault document...'
						deckExists={deckExists}
						mode={mode}
						setMode={(mode) => this.setState({ mode })}
					/>
					<Form.Group as={Col}>
						<Form.Label>Title</Form.Label>
						<Form.Control
							readOnly
							defaultValue={title}
							placeholder='Loading title...'
						/>
					</Form.Group>
					<Form.Group as={Col}>
						{isPowerpoint(documentType) && mode === 'deploy' && (
							<>
								<Form.Label>Zip file</Form.Label>
								<FileInput
									name='zip'
									id='zip'
									accept='.zip'
									clear={() => updateForm('zip', null)}
									onChange={this.onChange}
									download={download}
								/>
							</>
						)}
					</Form.Group>
					{!isDam ? (
						<Form.Group as={Col}>
							{!isEmpty(internalCategories) && <InternalCategories />}
							{!isEmpty(externalCategories) && <ExternalCategories />}
						</Form.Group>
					) : (
						<Form.Group as={Col}>
							<Form.Row>
								<Form.Group as={Col} controlId='damCategories'>
									<Form.Label>DAM Categories</Form.Label>
									<Form.Control
										placeholder={loadingDoc ? 'Loading...' : ''}
										name='collections'
										onChange={this.onChange}
										value={this.printCategory(mainCategory, secondCategory)}
										readOnly
									/>
								</Form.Group>
							</Form.Row>
						</Form.Group>
					)}
					<Form.Group as={Col}>
						<Form.Row>
							<LanguageSelector
								locals={locals}
								onChange={this.onChange}
								selected={language}
								disabled
							/>
							<ContentZoneSelector
								onChange={this.onChange}
								selected={contentZone}
								disabled
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>Country Codes</Form.Label>
								<Form.Control
									name='countryCodes'
									readOnly
									defaultValue={countryCodes}
									placeholder='Loading country codes...'
								/>
							</Form.Group>
						</Form.Row>
						{isPowerpoint(documentType) && (
							<Form.Row>
								<TransitionSelector as={Col} onChange={this.onChange} selected={transition} />
							</Form.Row>
						)}
					</Form.Group>
					{pdfThumbnail && (
						<Form.Group as={Col}>
							<Form.Row>
								<Form.Group as={Col} controlId='thumbnail'>
									<Card className='justify-content-center'>
										<Card.Header>Preview</Card.Header>
										<Card.Body className='d-flex justify-content-center'>
											<img
												alt='crop'
												width='310px'
												height='213.4px'
												src={pdfThumbnail || missingImage}
											/>
										</Card.Body>
									</Card>
								</Form.Group>
							</Form.Row>
						</Form.Group>
					)}
					{isDam && (
						<Form.Group as={Col} controlId='grouped'>
							<Form.Check
								custom
								name='grouped'
								type='checkbox'
								label='Grouped?'
								checked={grouped}
								onChange={({ currentTarget: { checked } }) =>
									updateForm('grouped', checked)
								}
							/>
						</Form.Group>
					)}
					{isPowerpoint(documentType) && (
						<Form.Group as={Col} controlId='animated'>
							<Form.Check
								custom
								name='animated'
								type='checkbox'
								label='Animated?'
								onChange={this.onChange}
								checked={animated}
							/>
						</Form.Group>
					)}
					{!isIbr && (
						<Form.Group as={Col} controlId='permissions'>
							<Form.Check
								custom
								name='permissions'
								type='checkbox'
								label='Restricted?'
								checked={permissions === 'R'}
								onChange={({ currentTarget: { checked } }) =>
									updateForm('permissions', checked ? 'R' : 'pub')
								}
							/>
						</Form.Group>
					)}
					{isDam && (
						<Form.Group as={Col} controlId='notify'>
							<Form.Check
								custom
								name='notify'
								type='checkbox'
								label='Send notification email for DAM updates?'
								onChange={this.onChange}
								value={notify}
								disabled={!isDam}
							/>
						</Form.Group>
					)}
					{TEST && (
						<Form.Group as={Col} controlId='test'>
							<Form.Check
								custom
								name='test'
								type='checkbox'
								label='Test?'
								onChange={this.onChange}
								checked={test}
							/>
						</Form.Group>
					)}
					{isPowerpoint(documentType) && operation === 'update' && (
						<Form.Group as={Col} controlId='useExperimentalImageMatcher'>
							<Form.Check
								custom
								disabled={useAutomaticVideoAndLinkPlacement || disableImageMatching}
								name='useExperimentalImageMatcher'
								type='checkbox'
								label='Use experimental image matcher to copy videos and links?'
								onChange={this.onChange}
								checked={useExperimentalImageMatcher}
							/>
						</Form.Group>
					)}
					{isPowerpoint(documentType) && (
						<Form.Group as={Col} controlId='useAutomaticVideoAndLinkPlacement'>
							<Form.Check
								custom
								disabled={useExperimentalImageMatcher}
								name='useAutomaticVideoAndLinkPlacement'
								type='checkbox'
								label='Use experimental pptx parser to copy videos and links?'
								onChange={this.onChange}
								checked={useAutomaticVideoAndLinkPlacement}
							/>
						</Form.Group>
					)}
					<Form.Group as={Col}>
						<Button
							variant='primary'
							size='lg'
							block
							onClick={this.submit}
							disabled={disabled()}
						>
							{mode === 'deploy' ? 'Next' : 'Update'}
						</Button>
					</Form.Group>
				</Form>
				<UploaderModal show={show} worker={worker} />
				<AspectRatioWarningModal show={this.state.warnAspectRatio} handleClose={this.toggleWarnAspectRatio} />
			</>
		);
	}
}

export default VaultUploaderForm;
