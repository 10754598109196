import React, { useCallback, useEffect, useState } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
import find from 'lodash/find';
import get from 'lodash/get';

import AuthModal from './LinksModal';
import ResultModal from '../shared/ResultModal';
import FileInput from '../uploader/FileInput';

import { handleError, base64ArrayBuffer } from './helpers';
import {
	authorizeUser,
	downloadLinkThumbnail,
	downloadAceLinkThumbnailPreview,
	fetchVaultLinks,
	fetchVaultAttachmentInfo,
	publishLinkThumbnail,
	uploadLinkThumbnail,
} from '../../redux/actions';

const VaultLinkThumbnailUpload = () => {
	const dispatch = useDispatchRequest();
	const [{ sessionId, vaultId }] = useUrlSearchParams();
	const { data: authorized, loading: authorizing } = useQuery({
		type: 'AUTHORIZE_USER',
		action: authorizeUser,
		defaultData: false,
		autoLoad: true,
		variables: [sessionId],
	});
	const [admin, setAdmin] = useState(false);
	const [showResultModal, setShowResultModal] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(true);
	const [error, setError] = useState(null);
	const [status, setStatus] = useState(null);
	const [success, setSuccess] = useState(null);
	const [preview, setPreview] = useState(null);
	const [attachment, setAttachment] = useState();
	const { data: links } = useQuery({
		type: 'FETCH_VAULT_LINKS',
		action: fetchVaultLinks,
		autoLoad: true,
	});

	useEffect(() => {
		const checkSession = async () => {
			try {
				console.log('user: ', authorized);
				const isAdmin = get(authorized, ['ace_admin__c'], false);
				setAdmin(isAdmin);
			} catch (err) {
				setAdmin(false);
				setError(err);
				setShowResultModal(true);
			}
		};
		checkSession();
	}, [authorized]);

	useEffect(() => {
		if (admin) {
			setShowAuthModal(false);
			previewAttachment();
		}
	}, [admin]);

	const handleFileChange = async (e) => {
		try {
			const file = e.target.files[0];
			const formData = new FormData();
			formData.append('file', file);
			await dispatch(uploadLinkThumbnail(vaultId, formData));
			const { data: previewData } = await dispatch(
				downloadAceLinkThumbnailPreview(vaultId)
			);
			const fileBuffer = await file.arrayBuffer();
			console.log(fileBuffer);
			setAttachment(base64ArrayBuffer(fileBuffer));
			setPreview(base64ArrayBuffer(previewData));
		} catch (err) {
			setError(handleError(err));
			setShowResultModal(true);
		}
	};

	const previewAttachment = useCallback(async () => {
		try {
			if (admin) {
				let { data: attachmentInfo } = await dispatch(
					fetchVaultAttachmentInfo(vaultId)
				);

				if (!attachmentInfo) {
					// Wait a few seconds and try again, for Tina's issue
					await new Promise((resolve) => setTimeout(resolve, 5000));
					const { data } = await dispatch(
						fetchVaultAttachmentInfo(vaultId)
					);
					attachmentInfo = data;
				}
				if (!attachmentInfo) {
					setStatus('No attachment found, check Vault entry.');
					setShowResultModal(true);
					return;
				}
				const { data: imageData } = await dispatch(
					downloadLinkThumbnail(vaultId, attachmentInfo?.id)
				);
				const formData = new FormData();
				formData.append(
					'file',
					new File([imageData], vaultId, { type: 'image/png' })
				);
				await dispatch(uploadLinkThumbnail(vaultId, formData));
				const { data: previewData } = await dispatch(
					downloadAceLinkThumbnailPreview(vaultId)
				);
				setAttachment(base64ArrayBuffer(imageData));
				setPreview(base64ArrayBuffer(previewData));
			}
		} catch (err) {
			console.error(err);
			setError(
				handleError(err) || 'You are not authorized to perform this function.'
			);
			setShowResultModal(true);
		}
	}, [admin]);

	const publish = async () => {
		try {
			if (admin) {
				await dispatch(publishLinkThumbnail(vaultId));
				setSuccess(
					`Link thumbnail for ${find(links, { id: vaultId }).name__v
					} was successfully updated`
				);
				setShowResultModal(true);
			}
		} catch (err) {
			setError(err);
			setShowResultModal(true);
		}
	}

	const showJumbotron =
		(!authorizing && !admin) ||
		(!showAuthModal && !showResultModal && (status || success || error));

	return (
		<Container>
			<Breadcrumb className='pt-5'>
				<Breadcrumb.Item active={!admin}>
					1. Authentication
				</Breadcrumb.Item>
				<Breadcrumb.Item active={admin}>2. Upload</Breadcrumb.Item>
				<Breadcrumb.Item active={success && showResultModal}>
					3. Confirm
				</Breadcrumb.Item>
				<Button
					style={{ margin: '-8px' }}
					className='ml-auto'
					onClick={publish}
					disabled={authorizing || !admin || showJumbotron}
				>
					Publish
				</Button>
			</Breadcrumb>
			{authorizing && !admin && (
				<Jumbotron>
					<h1>Authenticating...</h1>
				</Jumbotron>
			)}
			{showJumbotron && (
				<Jumbotron>
					<h1>You may now safely close this window</h1>
				</Jumbotron>
			)}
			{!authorizing && admin && !showJumbotron && (
				<Form>
					<h1>Update Link Thumbnail</h1>
					<Form.Group controlId='links'>
						<Form.Label>Link</Form.Label>
						<Form.Control as='select' value={vaultId} disabled>
							{links?.map((link) => (
								<option key={link.id} value={link.id}>
									{link.name__v}: {link.url__c}
								</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>Upload Custom Thumbnail</Form.Label>
						<FileInput
							accept='image/png'
							onChange={handleFileChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Row>
							<Form.Group as={Col} md='6'>
								<Card>
									<Card.Header>Vault Thumbnail</Card.Header>
									{attachment ? (
										<Card.Img 
											src={attachment} 
											className='mx-auto d-block'
											style={{
												width: '400px',
												height: '250px',
												margin: '50px',
											}} 
										/>
									) : (
										<div className='text-center vertical-center'>
											<Spinner animation='border' role='status'>
												<span className='sr-only'>Loading...</span>
											</Spinner>
										</div>
									)}
								</Card>
							</Form.Group>
							<Form.Group as={Col}>
								<Card>
									<Card.Header>Ace Thumbnail Preview</Card.Header>
									{preview ? (
										<Card.Img
											src={preview}
											style={{
												width: '400px',
												height: '250px',
												margin: '50px',
											}}
											className='mx-auto d-block vertical-center'
										/>
									) : (
										<div className='text-center vertical-center'>
											<Spinner animation='border' role='status'>
												<span className='sr-only'>Loading...</span>
											</Spinner>
										</div>
									)}
								</Card>
							</Form.Group>
						</Form.Row>
					</Form.Group>
				</Form>
			)}
			<AuthModal
				show={showAuthModal}
				authorized={admin}
				loading={authorizing}
				vaultId={vaultId}
				handleClose={() => setShowAuthModal(false)}
			/>
			<ResultModal
				show={showResultModal}
				vaultId={vaultId}
				error={error}
				preview={false}
				statusMsg={status || ''}
				successMsg={success || ''}
				errMsg={error || ''}
				handleClose={() => setShowResultModal(false)}
			/>
		</Container>
	);
};

export default VaultLinkThumbnailUpload;
