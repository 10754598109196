// NOTE: not sure why this is here, will try swapping with regular FileInput later...

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import bsCustomFileInput from 'bs-custom-file-input';

class FileInput extends React.Component {
	constructor(props) {
		super(props);
		this.input = React.createRef();
		this.state = {
			fileName: '',
		};
	}

	componentDidMount() {
		bsCustomFileInput.init();
	}

	componentWillUnmount() {
		bsCustomFileInput.destroy();
	}

	onChange = (event) => {
		this.props.onChange(event);

		this.setState({
			fileName: event.currentTarget.files[0].name,
		});
	};

	handleClick = (e) => {
		this.input.current.click();
	};

	render() {
		const { disabled, id, name, accept } = this.props;

		return (
			<InputGroup>
				<Form.Control
					placeholder='Browse...'
					defaultValue={this.state.fileName}
				/>
				<input
					type='file'
					id={id}
					name={name}
					ref={this.input}
					style={{ display: 'none' }}
					accept={this.props.accept}
					onChange={this.onChange}
				/>
				<InputGroup.Append>
					<Button
						variant='outline-secondary'
						onClick={this.handleClick}
						disabled={disabled}
					>
						Browse
					</Button>
				</InputGroup.Append>
			</InputGroup>
		);
	}
}

export default FileInput;
