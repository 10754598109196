// @flow

import clone from 'lodash/clone';
import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import type { Component } from 'react';
import type { Props, State } from './index';
import type { Crop, Rect, Scale } from '../../../typings';

import { handleError } from '../../uploader/helpers';
import {
	clear,
	clearAll,
	downloadJson,
	getDeckJson,
	getRect,
	getScaleBound,
	setScale,
	restoreRect,
	preloadImages,
	onImageLoaded,
	manuallyUpdateJson,
	setSlideJson,
	submit,
	nextSlide,
	prevSlide,
	setBackupSlide,
	getDefaultRect,
} from './index';

export const state: State = {
	crop: {
		x: 0,
		y: 0,
		height: 0,
		width: 0,
		unit: '%',
	},
	scale: {
		x: 0,
		y: 0,
	},
	done: false,
	error: false,
	loading: false,
	deckIsLoaded: false,
	deckIsInvalid: true,
	deck: '',
	isDam: false,
	images: [],
	slides: [],
	showJson: false,
	showHelpModal: false,
	showChooseLinkModal: false,
	showResultModal: false,
	oldVals: null,
	damJson: {},
	selectedSlide: 0,
	showHelp: false,
	arrayNumber: 0,
	property: 'links',
	selectedLinks: []
};

export const setCrop = function (): void {
	const { slides, selectedSlide, arrayNumber } = this.state;

	const getScale = getScaleBound.bind(this);

	if (has(slides, [selectedSlide, 'links'])) {
		const scale: Scale = getScale();
		const rect: Rect = get(
			slides,
			[selectedSlide, 'links', arrayNumber],
			getDefaultRect()
		);
		this.setState({ crop: restoreRect(rect, scale) });
	} else {
		this.setState({ crop: {} });
	}
};

export const onChangeLinks = function (
	this: Component<Props, State>,
	{
		currentTarget: { value, name },
	}: {
		currentTarget: {
			value: string | number,
			name: 'x' | 'y' | 'width' | 'height' | 'url',
		},
	}
): void {
	const { selectedSlide, slides, arrayNumber } = this.state;

	try {
		if (name === 'url') {
			this.setState({
				slides: set(
					slides,
					[selectedSlide, 'links', arrayNumber, 'url'],
					value
				),
			});
		} else if (typeof value === 'number') {
			this.setState({
				slides: set(
					slides,
					[selectedSlide, 'links', arrayNumber, 'rect', name],
					value
				),
			});
		}
	} catch (err) {
		if (isFunction(this.props.toggleError)) {
			this.props.toggleError(handleError(err));
		}
	}
};

export const onCropChangeLinks = function (
	this: Component<Props, State>,
	crop: Crop,
	percentCrop: Crop
): void {
	const { arrayNumber, selectedSlide, slides } = this.state;

	this.setState({ crop: percentCrop });

	if (slides[selectedSlide]) {
		const getScale = getScaleBound.bind(this);
		const scale: Scale = getScale();
		const rect: Rect = getRect(crop, scale);

		if (!has(slides, [selectedSlide, 'links'])) {
			set(slides, ['links', arrayNumber], { url: '', rect });
		} else {
			set(slides, [selectedSlide, 'links', arrayNumber, 'rect'], rect);
		}

		this.setState({ slides });
	}
};

export const changeLinkNumber = function (
	this: Component<Props, State>,
	{ currentTarget: { value } }: { currentTarget: { value: string } }
): void {
	const { selectedSlide, slides } = this.state;

	const getScale = getScaleBound.bind(this);

	this.setState({ arrayNumber: parseInt(value) - 1 }, (): void => {
		const { arrayNumber } = this.state;

		if (!has(slides, [selectedSlide, 'links', arrayNumber])) {
			slides[selectedSlide].links = {
				path: '',
				rect: getDefaultRect(),
			};
			this.setState({ crop: restoreRect(getDefaultRect(), getScale()) });
		} else {
			const rect: Rect = get(slides, [
				selectedSlide,
				'links',
				arrayNumber,
				'rect',
			]);

			this.setState({
				crop: restoreRect(rect, getScale()),
			});
		}
	});
};

export const changeSlide = function (
	this: Component<Props, State>,
	{ currentTarget: { value } }: { currentTarget: { value: string } }
): void {
	const getScale = getScaleBound.bind(this);
	this.setState({ selectedSlide: parseInt(value) - 1 }, () => {
		const { selectedSlide, slides } = this.state;
		// if it has links
		// reset the selected link to the beginning of the array
		// reset the crop selection
		if (has(slides, [selectedSlide, 'links'])) {
			const rect: Rect = get(slides, [selectedSlide, 'links', 0, 'rect']);

			this.setState({
				arrayNumber: 0,
				crop: restoreRect(rect, getScale()),
			});
		} else {
			this.setState({ arrayNumber: 0, crop: {} });
		}
	});
};

export const toggleLinkModal = function (
	this: Component<Props, State>,
	edit: boolean = false
) {
	const {
		slides,
		selectedSlide,
		arrayNumber,
		showChooseLinkModal,
	} = this.state;

	// if its on the first arrayNumber don't bump
	// otherwise, increment the array number

	// we also want to clone the old values so that we can restore them
	const oldVals: string = get(
		slides,
		[selectedSlide, 'links', arrayNumber, 'url'],
		''
	);

	if (!edit && !showChooseLinkModal) {
		const newArrayNumber: number =
			arrayNumber === 0 &&
				!has(slides, [selectedSlide, 'links', arrayNumber, 'url'])
				? arrayNumber
				: get(slides, [selectedSlide, 'links'], []).length;

		this.setState(
			{
				arrayNumber: newArrayNumber,
				oldVals: clone(oldVals),
				showChooseLinkModal: !showChooseLinkModal
			}
		);
	} else if (edit && !showChooseLinkModal) {
		this.setState({
			showChooseLinkModal: !showChooseLinkModal,
			oldVals: clone(oldVals),
		});
	} else {
		// if it already has rect data, don't center it
		if (has(slides, [selectedSlide, 'links', this.state.arrayNumber, 'rect'])) {
			this.setState({ showChooseLinkModal: !showChooseLinkModal });
		} else {
			// otherwise, center and set crop if there's url data
			if (!isEmpty(get(slides, [selectedSlide, 'links', arrayNumber, 'url']))) {
				const getScale = getScaleBound.bind(this);

				this.setState({
					crop: restoreRect(getDefaultRect(), getScale()),
					showChooseLinkModal: !showChooseLinkModal,
					slides: set(
						slides,
						[selectedSlide, 'links', this.state.arrayNumber, 'rect'],
						getDefaultRect()
					)
				});
			} else {
				this.setState({ showChooseLinkModal: !showChooseLinkModal });
			}
		}
	}
};

export const restoreOldVals = function (): void {
	const { oldVals, slides, selectedSlide, arrayNumber } = this.state;

	if (oldVals) {
		console.log('restoring old vals: ', oldVals);

		const newArrayNumber = arrayNumber > 0 ? oldVals.length - 1 : arrayNumber;

		this.setState({
			arrayNumber: newArrayNumber,
			slides: set(slides, [selectedSlide, 'video'], oldVals),
			crop: restoreRect(get(oldVals, [newArrayNumber, 'rect'])),
		});
	} else {
		this.clear();
	}
};

export const setSelectedLinks = function (selectedLinks): void {
	if (typeof selectedLinks === 'function') {
		const links = selectedLinks(this.state.selectedLinks);
		this.setState({ selectedLinks: links });
	} else {
		this.setState({ selectedLinks });
	}
};

export const copyLinksToSlides = (sourceSlide, targetSlideIds, slides) => {
    if (!sourceSlide.links) {
        console.log('No links found in source slide');
        return slides;
    }

	console.log('sourceSlide: ', sourceSlide);
	console.log('targetSlideIds: ', targetSlideIds);

    console.log(`Copying links from slide id ${sourceSlide.id} to slide ids ${targetSlideIds.join(', ')}`);

    const sourceLinks = [...sourceSlide.links];

    const updatedSlides = slides.map(slide => {
        if (targetSlideIds.includes(slide.id)) {
            return {
                ...slide,
                links: slide.links ? [...slide.links, ...sourceLinks] : sourceLinks
            };
        }
        return slide;
    });

    updatedSlides.forEach((slide) => {
        if (targetSlideIds.includes(slide.id)) {
            console.log(`Slide id ${slide.id} after modification: `, slide);
        }
    });

    return updatedSlides;
};

export const initialize = function (self: Component<Props, State>): void {
	self.setScale = setScale.bind(self);
	self.getDeckJson = getDeckJson.bind(self);
	self.setCrop = setCrop.bind(self);
	self.preloadImages = preloadImages.bind(self);
	self.changeSlide = changeSlide.bind(self);
	self.changeLinkNumber = changeLinkNumber.bind(self);
	self.clear = clear.bind(self, 'links');
	self.clearAll = clearAll.bind(self, 'links');
	self.onChange = onChangeLinks.bind(self);
	self.onCropChange = onCropChangeLinks.bind(self);
	self.onImageLoaded = onImageLoaded.bind(self);
	self.submit = submit.bind(self, 'links');
	self.toggleLinkModal = toggleLinkModal.bind(self);
	self.restoreOldVals = restoreOldVals.bind(self);
	self.nextSlide = nextSlide.bind(self);
	self.prevSlide = prevSlide.bind(self);
	self.manuallyUpdateJson = manuallyUpdateJson.bind(self);
	self.downloadJson = downloadJson.bind(self);
	self.setSlideJson = setSlideJson.bind(self);
	self.setBackupSlide = setBackupSlide.bind(self);
	self.copyLinksToSlides = copyLinksToSlides.bind(self);
};
