import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Button, Row, Col } from 'react-bootstrap';

const CopyLinksComponent = ({ slides, sourceSlideId, copyLinksToSlides, setSlides }) => {
    const [selected, setSelected] = useState([]);

    const handleButtonClick = () => {
        const sourceSlide = slides.find(slide => slide.id === sourceSlideId);
        const selectedSlideIds = selected.map(slide => slide.id);
        const updatedSlides = copyLinksToSlides(sourceSlide, selectedSlideIds, slides);
        setSlides(updatedSlides);
    };

    return (
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Copy Links To</Form.Label>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Typeahead
                            id="slide-select"
                            multiple
                            onChange={setSelected}
                            options={slides}
                            labelKey="id"
                            placeholder="Select slides..."
                            selected={selected}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Button variant="primary" onClick={handleButtonClick} style={{
                            height: "38px",
                            width: "100%"
                        }}>
                            Copy Links
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form.Group>
        </Form.Row>
    );
}

export default CopyLinksComponent;
