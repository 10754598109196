import { css } from 'aphrodite';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import clone from 'lodash/clone';
import get from 'lodash/get';

import Carousel from './Carousel';
import HelpModal from '../help/videoEditorHelp';
import ResultModal from '../shared/ResultModal';
import UploaderModal from '../shared/UploaderModal';
import OverwriteModal from './OverwriteModal';
import ChooseVideoModal from './ChooseVideoModal';
import EditorButtonGroup from '../editor/EditorButtonGroup';
import EditorSlideSelector from '../editor/EditorSlideSelector';
import JsonSlideSelector from './JsonSlideSelector';

import { state, initialize } from './helpers/videos';
import { getVaultId } from './helpers';

import styles from './styles';

import 'prismjs/components/prism-json';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

class DeckVideoEditor extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { ...clone(state), isShiftPressed: false };
		this.image;
		this.interval;

		this.initialize(this);
	}

	initialize = initialize.bind(this);

	componentDidMount() {
		if (typeof window.onresize === 'function') {
			window.onresize = null;
		}

		window.onresize = this.setScale;
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	}

	componentWillUnmount() {
		window.onresize = null;
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
	}

	handleKeyDown = (event) => {
		if (event.key === 'Shift') {
			this.setState((prevState) => ({
				isShiftPressed: true,
				crop: { ...prevState.crop, aspect: prevState.aspect },
			}));
		} else if (event.key === 'Delete') {
			if (this.state.property) {
				this.clear(this.state.property);
			}
		}
	};

	handleKeyUp = (event) => {
		if (event.key === 'Shift') {
			this.setState((prevState) => ({
				isShiftPressed: false,
				crop: { ...prevState.crop, aspect: null }
			}));
		}
	};

	changeDeck = ([deck]) => {
		if (!deck) return;

		const vaultId = getVaultId(deck);

		console.log('deck: ', deck);

		if (deck) {
			this.setState(
				{
					deck, // needs to use array value or typeahead crashes
					isDam: vaultId?.includes('DAM'),
				},
				async () => await this.getDeckJson({ vaultId })
			);
		}
	};

	setAspectRatio = (aspect) => this.setState({ aspect });

	render() {
		const { aceVideos, vaultVideos, decks, username, progress } = this.props;

		const {
			crop,
			damJson,
			done,
			deck,
			deckIsLoaded,
			deckIsInvalid,
			backup,
			hasBackups,
			backupJson,
			error,
			file,
			images,
			isDam,
			loading,
			selectedSlide,
			slides,
			showHelpModal,
			showJson,
			showUploadModal,
			showChooseVideoModal,
			showOverwriteModal,
			showResultModal,
			selectedBackupSlide,
			arrayNumber,
			upload,
			worker,
			property,
		} = this.state;

		const slide = slides[selectedSlide] || {};
		const hasVideo = !!slide.video;

		const hasRect =
			get(slide, ['video', arrayNumber, 'path']) &&
			get(slide, ['video', arrayNumber, 'rect']);

		return (
			<>
				<Container>
					{done && (
						<Alert
							variant='success'
							dismissible
							onClose={() => this.setState({ done: false })}
						>
							Json uploaded successfully
						</Alert>
					)}
					{error && (
						<Alert
							variant='danger'
							dismissible
							onClose={() => this.setState({ error: false })}
						>
							{error || 'There was an error, check the console'}
						</Alert>
					)}
					<Form>
						<Form.Row>
							<h1>Video Editor</h1>
							<p
								onClick={() =>
									this.setState({ showHelpModal: !this.state.showHelpModal })
								}
							>
								ⓘ
							</p>
						</Form.Row>
						<Form.Row>
							<Carousel
								arrayNumber={arrayNumber}
								crop={crop}
								deck={deck.vaultId}
								deckIsLoaded={deckIsLoaded}
								deckIsInvalid={deckIsInvalid}
								hasObject={hasVideo}
								images={images}
								slides={slides}
								selectedSlide={selectedSlide}
								showChooseVideoModal={showChooseVideoModal}
								changeSelectedObject={this.changeVideoNumber}
								onCropChange={this.onCropChange}
								onImageLoaded={this.onImageLoaded}
								onLoadedVideoData={this.onLoadedVideoData}
								setAspectRatio={this.setAspectRatio}
								nextSlide={() => {
									const nextSlide = selectedSlide + 1;
									if (nextSlide < slides.length) {
										const event = {
											currentTarget: { value: (nextSlide + 1).toString() },
										};
										this.changeSlide(event);
									}
								}}
								prevSlide={() => {
									const nextSlide = selectedSlide - 1;
									if (!(nextSlide < 0)) {
										const event = {
											currentTarget: { value: (nextSlide + 1).toString() },
										};
										this.changeSlide(event);
									}
								}}
								property='video'
							/>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>Vault ID</Form.Label>
								<Typeahead
									id='deck-typeahead'
									options={decks}
									labelKey={option => getVaultId(option)}
									placeholder='Enter deck vault ID'
									onChange={this.changeDeck}
									isInvalid={deckIsInvalid}
									isValid={deckIsLoaded && !deckIsInvalid}
								/>
								<Form.Control.Feedback type='invalid'>
									Deck is invalid
								</Form.Control.Feedback>
								<Form.Control.Feedback type='valid'>
									Deck is valid
								</Form.Control.Feedback>
							</Form.Group>
							<EditorSlideSelector
								deckIsLoaded={deckIsLoaded}
								property={property}
								changeSlide={this.changeSlide}
								slides={slides}
								selectedSlide={selectedSlide}
							/>
							{hasBackups && (
								<JsonSlideSelector
									deckIsLoaded={deckIsLoaded}
									property={property}
									setSlideJson={this.setSlideJson}
									setBackupSlide={this.setBackupSlide}
									slides={get(backupJson, [backup, 'slides'])}
									selectedSlide={selectedBackupSlide}
								/>
							)}
						</Form.Row>
						<EditorButtonGroup
							deckIsLoaded={deckIsLoaded}
							deckIsInvalid={deckIsInvalid}
							property={property}
							togglePropertyModal={this.toggleVideoModal}
							clear={this.clear}
							hasRect={hasRect}
						/>
						<Form.Row>
							<Form.Group as={Col}>
								<Button
									variant='primary'
									onClick={this.submitVideo}
									disabled={!deckIsLoaded || deckIsInvalid}
									block
								>
									{loading && (
										<Spinner
											as='span'
											animation='grow'
											role='status'
											aria-hidden='true'
											size='sm'
										/>
									)}
									Save
								</Button>
								<Button
									variant='primary'
									onClick={this.downloadJson}
									disabled={!deckIsLoaded}
									block
								>
									Download JSON
								</Button>
							</Form.Group>
						</Form.Row>
						<Collapse in={showJson}>
							<Form.Row className={css(styles.json)}>
								<Col>
									<Editor
										value={JSON.stringify(isDam ? damJson : slides, null, 3)}
										onValueChange={this.manuallyUpdateJson}
										highlight={(code) => highlight(code, languages.json)}
										padding={10}
										textareaClassName={css(styles.pre)}
										style={{
											fontFamily: 'monospace',
											fontWeight: '400',
											fontSize: 14,
										}}
									/>
								</Col>
							</Form.Row>
						</Collapse>
					</Form>
				</Container>
				<HelpModal
					show={showHelpModal}
					handleClose={() =>
						this.setState({ showHelpModal: !this.state.showHelpModal })
					}
				/>
				<UploaderModal show={showUploadModal} worker={worker} />
				<OverwriteModal
					show={showOverwriteModal}
					file={file}
					handleClose={() =>
						this.setState({
							showOverwriteModal: !this.state.showOverwriteModal,
						})
					}
					uploadVideo={this.uploadVideo}
				/>
				<ChooseVideoModal
					file={file}
					show={showChooseVideoModal}
					arrayNumber={arrayNumber}
					deckIsLoaded={deckIsLoaded}
					slides={slides}
					selectedSlide={selectedSlide}
					videos={upload === 1 ? vaultVideos : aceVideos}
					upload={upload}
					username={username}
					progress={progress}
					oldVals={this.state.oldVals}
					clearVideo={this.clear}
					downloadVideo={this.downloadVideo}
					uploadVideo={this.props.uploadVideo}
					setVaultVideo={this.setVaultVideo}
					setUpload={(value) => this.setState({ upload: value })}
					onPathChange={this.onPathChange}
					onFileChange={this.onFileChange}
					handleClose={() =>
						this.setState({
							showChooseVideoModal: !this.state.showChooseVideoModal,
						})
					}
					restoreOldVals={this.restoreOldVals}
				/>
				<ResultModal
					show={showResultModal}
					vaultId={deck.vaultId}
					error={error}
					successMsg={`${deck.vaultId} was successfully updated`}
					errMsg={error || `There was an error updating ${deck.vaultId}`}
					handleClose={() =>
						this.setState({ showResultModal: !this.state.showResultModal })
					}
				/>
			</>
		);
	}
}

export default DeckVideoEditor;
