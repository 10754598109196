import { css } from 'aphrodite';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactCrop from 'react-image-crop';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';

import { computeVideoRect, getVideoPath } from './helpers/videos';

import styles from './styles';

const CMSCarousel = ({
	crop,
	deck,
	hasObject,
	images,
	property,
	slides,
	selectedSlide,
	changeSelectedObject,
	onCropChange,
	onDragEnd,
	onImageLoaded,
	onLoadedVideoData,
	nextSlide,
	prevSlide,
	setAspectRatio,
}) => {
	const getImageWidth = () => {
		const image = document.querySelector('.ReactCrop__image');

		if (image) {
			const width = image.naturalWidth;

			if (width === 1730) {
				return 1730;
			} else if (width === 1920) {
				return 1920;
			} else {
				return width;
			}
		} else {
			console.log("No image found");
			return null;
		}
	}

	return (
		<>
			<Button
				className={css(styles.left)}
				onClick={prevSlide}
				variant='secondary'
			>
				{'<'}
			</Button>
			<Card style={{ width: '94%' }}>
				<Card.Header>{deck ? deck : 'Preview'}</Card.Header>
				<ReactCrop
					crop={crop}
					disabled={!hasObject}
					src={
						images[selectedSlide] ||
						require('../../../public/images/missing_1730_1.png')
					}
					onChange={onCropChange}
					onImageLoaded={onImageLoaded}
					onDragEnd={isFunction(onDragEnd) ? onDragEnd : noop}
					keepSelection={true}
					imageStyle={{
						maxWidth: '100%',
						maxHeight: '100%',
					}}
				>
					{has(slides, [selectedSlide, 'video']) &&
						slides[selectedSlide].video.map(({ rect, path }, idx) => (
							<video
								key={idx}
								className={css(styles.video)}
								style={{
									...computeVideoRect(rect, getImageWidth()),
									cursor: 'pointer',
								}}
								autoPlay
								muted
								playsInline
								loop
								src={getVideoPath(path)}
								onLoadedData={onLoadedVideoData}
								onClick={(e) => {
									if (property === 'video') {
										changeSelectedObject({
											currentTarget: { value: (idx + 1).toString() },
										});
										// get aspect ratio of video
										setAspectRatio(e.target.videoWidth / e.target.videoHeight);
									}
								}}
							></video>
						))}
					{has(slides[selectedSlide], 'links') &&
						slides[selectedSlide].links.map(({ url, rect }, idx) => {
							if (rect && !isEmpty(rect)) {
								return (
									<div
										key={idx}
										style={{
											...computeVideoRect(rect, getImageWidth()),
											border: '5px solid yellow',
											position: 'absolute',
											textAlign: 'center',
											color: 'yellow',
											backgroundColor: 'grey',
											opacity: 0.5,
											display: 'flex',
											fontWeight: 'bold',
											cursor: property === 'links' ? 'pointer' : 'unset',
										}}
										onClick={() => {
											if (property === 'links') {
												changeSelectedObject({
													currentTarget: { value: (idx + 1).toString() }
												});
											}
										}}
									>
										<p className='m-auto'>L I N K : {url}</p>
									</div>
								);
							}
						})}
				</ReactCrop>
			</Card>
			<Button
				className={css(styles.right)}
				onClick={nextSlide}
				variant='secondary'
			>
				{'>'}
			</Button>
		</>
	);
};

CMSCarousel.defaultProps = {
	slides: [],
};

export default CMSCarousel;
