import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const ShowParams = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const query = {};

	for (const p of params.keys()) {
		query[p] = params.get(p);
	}

	return (
		<Container>
			<pre>{JSON.stringify(query, null, 3)}</pre>
		</Container>
	);
};

export default ShowParams;
