// @flow

import path from 'path';
import sortBy from 'lodash/sortBy';
import types from '../types';

// $FlowFixMe[cannot-resolve-module]: flow is wigging out on importing this module
import type { Action, Worker } from '../../typings';

export const checkVaultDeckIsWideScreen = (vaultId: string): Action => ({
	type: types.CHECK_VAULT_DECK_IS_WIDESCREEN,
	request: {
		url: `/api/ace/staging/${vaultId}/widescreen`,
		method: 'get'
	}
});

export const checkAceDeckIsWideScreen = (vaultId: string): Action => ({
	type: types.CHECK_ACE_DECK_IS_WIDESCREEN,
	request: {
		url: `/api/ace/deck/PRS/${vaultId}/widescreen`,
		method: 'get'
	}
});

export const fetchLogs = (): Action => ({
	type: types.FETCH_LOGS,
	request: {
		url: '/api/logs',
		method: 'get'
	},
});

export const fetchPodcastThumbnail = (id: string): Action => ({
	type: types.FETCH_PODCAST_THUMBNAIL,
	request: {
		url: `/api/vault/documents/${id}/thumbnail`,
		method: 'get',
	},
});

export const fetchDeckBackups = (vaultId: string): Action => ({
	type: types.FETCH_DECK_BACKUPS,
	request: {
		url: `/api/deck/${vaultId}/backups`,
		method: 'get',
	},
});

export const fetchRoutes = (): Action => ({
	type: types.FETCH_ROUTES,
	request: {
		url: '/api/routes/',
		method: 'get',
	},
});

export const dereferenceRelationships = (id: number): Action => ({
	type: types.DEREFERENCE_RELATIONSHIPS,
	request: {
		url: `/api/vault/relationships/${id}`,
		method: 'get',
	},
});

export const checkIfVideoExists = (vaultId: string): Action => ({
	type: types.CHECK_IF_VIDEO_EXISTS,
	request: {
		url: `/api/ace/video/${vaultId}.mp4`,
		method: 'get',
	},
});

export const tokenize = ({
	id,
	contentType,
	vaultId,
}: {
	id: string,
	contentType: string,
	vaultId: string,
}): Action => ({
	type: types.TOKENIZE,
	request: {
		url: '/api/tokenizer/',
		method: 'post',
		data: {
			id,
			contentType,
			vaultId,
		},
	},
});

export const startTokenizer = (): Action => ({
	type: types.FETCH_TOKENIZER_WORKERS,
	request: {
		url: '/api/tokenizer/all',
		method: 'post',
	},
	meta: {
		getData: (data) =>
			Array.isArray(data) ? sortBy(data, (o) => parseInt(o.id)).reverse() : [],
	},
});

export const cleanTokenizerWorkers = (): Action => ({
	type: types.CLEAN_TOKENIZER_WORKERS,
	request: {
		url: '/api/tokenizer/jobs',
		method: 'delete',
	},
	meta: {
		mutations: {
			[types.FETCH_TOKENIZER_WORKERS]: {
				updateDataOptimistic: () => [],
				revertData: (data) => data,
			},
		},
	},
});

export const fetchTokenizerWorkers = (): Action => ({
	type: types.FETCH_TOKENIZER_WORKERS,
	request: {
		url: '/api/tokenizer/jobs/',
		method: 'get',
	},
	meta: {
		getData: (data) =>
			Array.isArray(data) ? sortBy(data, (o) => parseInt(o.id)).reverse() : [],
	},
});

export const fetchTokenizerWorker = (id: string | number): Action => ({
	type: types.FETCH_TOKENIZER_WORKER,
	request: {
		url: `/api/tokenizer/jobs/${id}`,
		method: 'get',
	},
});

export const restartTokenizerWorker = (worker: Worker): Action => ({
	type: types.RESTART_TOKENIZER_WORKER,
	request: {
		url: `/api/tokenizer/jobs/${worker.id}/restart`,
		method: 'get',
	},
	meta: {
		mutations: {
			[types.FETCH_TOKENIZER_WORKERS]: {
				updateDataOptimistic: (data) => {
					data.filter((v) => v.id !== worker.id);
					worker.progress = 0;
					worker.finishedOn = undefined;
					return [...data, worker];
				},
			},
		},
	},
});

export const killTokenizerWorker = (worker: Worker): Action => ({
	type: types.KILL_TOKENIZER_WORKER,
	request: {
		url: `/api/tokenizer/jobs/${worker.id}`,
		method: 'delete',
	},
	meta: {
		mutations: {
			[types.FETCH_TOKENIZER_WORKERS]: {
				updateDataOptimistic: (data) => data.filter((v) => v.id !== worker.id),
				revertData: (data) => [worker, ...data],
			},
		},
	},
});

export const fetchStagedIndexJson = (workerId: string): Action => ({
	type: types.FETCH_STAGED_INDEX_JSON,
	request: {
		url: `/api/workers/${workerId}`,
		method: 'get',
	},
});

export const toggleDarkMode = (setting: boolean): Action => ({
	type: types.TOGGLE_DARKMODE,
	payload: setting,
});

export const getDarkMode = (): Action => ({
	type: types.GET_DARKMODE,
});

export const fetchVaultLinks = (): Action => ({
	type: types.FETCH_VAULT_LINKS,
	request: {
		url: '/api/vault/links',
		method: 'get',
	},
});

export const clearLinkAttachment = (
	id: string,
	attachmentId: string
): Action => ({
	type: types.CLEAR_VAULT_LINK_THUMBNAIL,
	request: {
		url: `/api/vault/links/${id}/attachment/${attachmentId}`,
		method: 'delete',
	},
});

export const uploadVaultLinkThumbnail = (
	id: string,
	formData: FormData
): Action => ({
	type: types.UPLOAD_VAULT_LINK_THUMBNAIL,
	request: {
		url: `/api/vault/links/${id}/attachment`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	},
});

export const downloadAceLinkThumbnailPreview = (id: string): Action => ({
	type: types.FETCH_ACE_LINK_THUMBNAIL_PREVIEW,
	request: {
		url: `/api/ace/links/${id}/thumbnail`,
		method: 'get',
		responseType: 'arraybuffer',
	},
});

export const fetchVaultAttachmentInfo = (id: string): Action => ({
	type: types.FETCH_VAULT_LINK_THUMBNAIL,
	request: {
		url: `/api/vault/links/${id}/attachments`,
		method: 'get',
	},
});

export const downloadLinkThumbnail = (
	id: string,
	attachmentId: string
): Action => ({
	type: types.DOWNLOAD_VAULT_LINK_THUMBNAIL,
	request: {
		url: `/api/vault/links/${id}/attachment/${attachmentId}/download`,
		method: 'get',
		responseType: 'arraybuffer',
	},
});

export const uploadLinkThumbnail = (
	id: string,
	formData: FormData
): Action => ({
	type: types.STAGE_VAULT_LINK_THUMBNAIL,
	request: {
		url: `/api/ace/links/${id}/thumbnail`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	},
});

export const publishLinkThumbnail = (id: string): Action => ({
	type: types.PUBLISH_ACE_LINK_THUMBNAIL,
	request: {
		url: `/api/ace/links/${id}`,
		method: 'post',
	},
});

export const fetchPdfThumbnail = (vaultId: string): Action => ({
	type: types.FETCH_PDF_THUMBNAIL,
	request: {
		url: `/api/pdf/${vaultId}`,
		method: 'get',
		responseType: 'arraybuffer',
	},
});

export const fetchLocals = (): Action => ({
	type: types.FETCH_LOCALS,
	request: {
		url: '/api/ace/locals',
		method: 'get',
	},
});

export const fetchLatestCommitSha = (): Action => ({
	type: types.FETCH_LATEST_COMMIT_SHA,
	request: {
		url: '/api/git/commits/latest',
		method: 'get',
	},
});

export const authorizeUser = (sessionId: string): Action => ({
	type: types.AUTHORIZE_USER,
	request: {
		url: `/api/authorize/${sessionId}`,
		method: 'get',
	},
});

export const fetchUser = (sessionId: string): Action => ({
	type: types.FETCH_VAULT_USER,
	request: {
		url: `/api/vault/user/${sessionId}`,
		method: 'get',
	},
	meta: {
		getData: (data, currentData) => data,
	},
});

export const updateProgress = ({
	loaded,
	total,
}: {
	loaded: number,
	total: number,
}): Action => ({
	type: types.UPDATE_PROGRESS,
	payload: {
		loaded,
		total,
	},
});

export const fetchWorkers = (): Action => ({
	type: types.FETCH_WORKERS,
	request: {
		url: '/api/jobs/',
		method: 'get',
	},
	meta: {
		getData: (data) =>
			Array.isArray(data) ? sortBy(data, (o) => parseInt(o.id)).reverse() : [],
	},
});

export const fetchWorker = (id: string | number): Action => ({
	type: types.FETCH_WORKER,
	request: {
		url: `/api/jobs/${id}`,
		method: 'get',
	},
});

export const fetchLastWorker = (vaultId: string): Action => ({
	type: types.FETCH_LAST_WORKER,
	request: {
		url: `/api/jobs/last/${vaultId}`,
		method: 'get',
	},
});

export const restartWorker = (worker: Worker): Action => ({
	type: types.RESTART_WORKER,
	request: {
		url: `/api/jobs/restart/${worker.id}`,
		method: 'get',
	},
	meta: {
		mutations: {
			[types.FETCH_WORKERS]: {
				updateDataOptimistic: (data) => {
					data.filter((v) => v.id !== worker.id);
					worker.progress = 0;
					worker.finishedOn = undefined;
					return [...data, worker];
				},
			},
		},
	},
});

export const clearFailedWorkers = (): Action => ({
	type: types.CLEAR_ALL_FAILED_WORKERS,
	request: {
		url: '/api/jobs/kill/failed',
		method: 'delete',
	},
});

export const clearFinishedWorkers = (): Action => ({
	type: types.CLEAR_ALL_FINISHED_WORKERS,
	request: {
		url: '/api/jobs/kill/finished',
		method: 'delete',
	},
});

export const clearWorker = (worker: Worker): Action => ({
	type: types.CLEAR_WORKER,
	request: {
		url: `/api/jobs/kill/${worker.id}`,
		method: 'delete',
	},
	meta: {
		mutations: {
			[types.FETCH_WORKERS]: {
				updateDataOptimistic: (data) => data.filter((v) => v.id !== worker.id),
				revertData: (data) => [worker, ...data],
			},
		},
	},
});

export const cleanWorkers = (): Action => ({
	type: types.CLEAN_WORKERS,
	request: {
		url: '/api/jobs/all',
		method: 'delete',
	},
	meta: {
		mutations: {
			[types.FETCH_WORKERS]: {
				updateDataOptimistic: () => [],
				revertData: (data) => data,
			},
		},
	},
});

export const login = (formData: {
	username: string,
	password: string,
}): Action => ({
	type: types.LOGIN,
	request: {
		url: '/api/login',
		method: 'post',
		data: formData,
	},
});

export const logout = (): Action => ({
	type: types.LOGOUT,
});

export const updateLoginForm = (name: string, value: string): Action => ({
	type: types.UPDATE_LOGIN_FORM,
	payload: {
		name,
		value,
	},
});

export const updateSessionId = (sessionId: string): Action => ({
	type: types.UPDATE_SESSION_ID,
	payload: sessionId,
});

export const validateSession = (sessionId: string): Action => ({
	type: types.VALIDATE_SESSION,
	request: {
		url: '/api/session',
		method: 'post',
		data: { sessionId },
	},
});

export const downloadDocumentVideo = (id: string | number): Action => ({
	type: types.DOWNLOAD_VAULT_DOCUMENT_VIDEO,
	request: {
		url: `/api/vault/documents/${id}/video`,
		method: 'get',
		responseType: 'blob',
	},
	meta: {
		measureDownloadProgress: true,
	},
});

export const downloadDocumentAudio = (id: string | number): Action => ({
	type: types.DOWNLOAD_VAULT_DOCUMENT_AUDIO,
	request: {
		url: `/api/vault/documents/${id}/audio`,
		method: 'get',
		responseType: 'blob', // needs to be blob for pdfs
	},
});

export const downloadDocumentThumbnail = (id: string | number): Action => ({
	type: types.DOWNLOAD_VAULT_DOCUMENT_THUMBNAIL,
	request: {
		url: `/api/vault/documents/${id}/thumbnail`,
		method: 'get',
		responseType: 'blob',
	},
});

export const downloadDocumentRendition = (id: string | number): Action => ({
	type: types.DOWNLOAD_VAULT_DOCUMENT_RENDITION,
	request: {
		url: `/api/vault/documents/${id}/rendition`,
		method: 'get',
		responseType: 'blob', // needs to be blob for pdfs
	},
});

export const downloadDocumentSource = (id: string | number): Action => ({
	type: types.DOWNLOAD_VAULT_DOCUMENT_SOURCE,
	request: {
		url: `/api/vault/documents/${id}/source`,
		method: 'get',
		responseType: 'blob',
	},
	meta: {
		trackProgress: true,
	},
});

export const updateDeckData = (formData: FormData): Action => ({
	type: types.UPDATE_DECK_DATA,
	request: {
		url: `/api/update/deck/data`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	},
});

export const updateForm = (
	name,
	value
): Action => ({
	type: types.UPDATE_FORM,
	payload: {
		name,
		value,
	},
});

export const postForm = (formData: FormData): Action => ({
	type: types.POST_FORM,
	request: {
		url: '/api/upload',
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	},
});

export const publish = (formData: FormData): Action => ({
	type: types.PUBLISH,
	request: {
		url: '/api/deploy',
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData
	},
});

export const uploadPdfThumbnail = (
	vaultId: string,
	formData: FormData
): Action => ({
	type: types.UPLOAD_PDF_THUMBNAIL,
	request: {
		url: `/api/ace/content/pdf/${vaultId}/thumbnail`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'arraybuffer',
		data: formData,
	},
});

export const uploadVideoThumbnail = (
	vaultId: string,
	formData: FormData
): Action => ({
	type: types.UPLOAD_PDF_THUMBNAIL,
	request: {
		url: `/api/ace/content/video/${vaultId}/thumbnail`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	},
});

export const fetchContent = (type?: string): Action => {
	let url = '/api/ace/content';
	if (type === 'PRS' || type === 'VID' || type === 'PDF' || type === 'POD') {
		url += `/all/${type}`;
		// check if the user is passing the vault ID
	} else if (typeof type !== 'undefined' && type.includes('-')) {
		url += `/${type}`;
	} else {
		url += '/all';
	}

	return {
		type: types.FETCH_CONTENT,
		request: {
			url,
			method: 'get',
		}
	};
};

export const removeContent = (
	item: { contentType: string, vaultId: string },
	username: string
): Action => ({
	type: types.REMOVE_CONTENT,
	request: {
		url: item.contentType
			? `/api/ace/content/remove/${item.contentType}/${item.vaultId}`
			: `/api/ace/content/remove/${item.vaultId}`,
		method: 'delete',
		headers: {
			Username: username,
		},
	},
	meta: {
		mutations: {
			[types.FETCH_CONTENT]: {
				updateDataOptimistic: (data) =>
					data.filter((v) => v.vaultId !== item.vaultId),
				revertData: (data) => [item, ...data],
			},
		},
	},
});

export const removeDams = (
	item: { vaultId: string },
	username: string,
	notify
): Action => ({
	type: types.REMOVE_DAMS,
	request: {
		url: notify
			? `/api/ace/dams/remove/${item.vaultId}?notify=true`
			: `/api/ace/dams/remove/${item.vaultId}`,
		method: 'delete',
		headers: {
			Username: username,
		},
	},
	meta: {
		mutations: {
			[types.FETCH_CONTENT]: {
				updateDataOptimistic: (data) =>
					data.filter((v) => v.vaultId !== item.vaultId),
				revertData: (data) => [item, ...data],
			},
		},
	},
});

export const fetchDeckIndexJson = (vaultId: string): Action => ({
	type: types.FETCH_DECK_INDEX,
	request: {
		url: `/api/ace/decks/${vaultId}`,
		method: 'get',
	},
});

export const fetchVideos = (): Action => ({
	type: types.FETCH_VIDEOS,
	request: {
		url: '/api/ace/videos/index',
		method: 'get',
	},
	meta: {
		getData: (data) => {
			return data.map((o) => path.basename(o, '.mp4'));
		},
	},
});

export const uploadVideo = (formData: FormData): Action => ({
	type: types.UPLOAD_VIDEO,
	request: {
		url: '/api/ace/content/video',
		method: 'post',
		data: formData,
	},
	meta: {
		measureUploadProgress: true,
	},
});

// vault routes

export const fetchCountryCodes = (): Action => ({
	type: types.FETCH_VAULT_COUNTRY_CODES,
	request: {
		url: '/api/vault/countries/',
		method: 'get',
	},
});

export const fetchVaultDeck = (vaultId: string): Action => ({
	type: types.FETCH_VAULT_DECK,
	request: {
		url: `/api/vault/decks/${vaultId}`,
		method: 'get',
	},
});

export const fetchAllVaultDecks = (): Action => ({
	type: types.FETCH_VAULT_ALL_DECKS,
	request: {
		url: '/api/vault/decks/index',
		method: 'get',
	},
});

export const fetchAllVaultDocuments = (): Action => ({
	type: types.FETCH_VAULT_ALL_DOCUMENTS,
	request: {
		url: '/api/vault/documents',
		method: 'get',
	},
});

export const fetchAllVaultDams = (): Action => ({
	type: types.FETCH_VAULT_ALL_DAMS,
	request: {
		url: '/api/vault/dams/index',
		method: 'get',
	},
});

export const fetchAllVaultPdfs = (): Action => ({
	type: types.FETCH_VAULT_ALL_PDFS,
	request: {
		url: '/api/vault/pdfs/index',
		method: 'get',
	},
});

export const fetchAllVaultVideos = (): Action => ({
	type: types.FETCH_VAULT_ALL_VIDEOS,
	request: {
		url: '/api/vault/videos/index',
		method: 'get',
	},
});

export const fetchAllVaultPodcasts = (): Action => ({
	type: types.FETCH_VAULT_ALL_PODCASTS,
	request: {
		url: '/api/vault/podcasts/index',
		method: 'get',
	},
});

export const searchVault = (vaultId: string): Action => ({
	type: types.SEARCH_VAULT,
	request: {
		url: `/api/vault/search/${vaultId}`,
		method: 'get',
	},
});

export const fetchNewCategories = (): Action => ({
	type: types.FETCH_VAULT_NEW_CATEGORIES,
	request: {
		url: '/api/vault/newCategories',
		method: 'get',
	},
});

export const fetchCategoriesInternal = (): Action => ({
	type: types.FETCH_CATEGORIES_INTERNAL,
	request: {
		url: '/api/vault/categories/internal',
		method: 'get',
	},
});

export const fetchCategoriesExternal = (): Action => ({
	type: types.FETCH_CATEGORIES_EXTERNAL,
	request: {
		url: '/api/vault/categories/external',
		method: 'get',
	},
});

export const fetchCategoriesCombined = (): Action => ({
	type: types.FETCH_CATEGORIES_COMBINED,
	request: {
		url: '/api/vault/categories/combined',
		method: 'get',
	},
});
