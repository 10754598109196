import isBoolean from 'lodash/isBoolean';
import types from '../types';

const initialState = false;

const darkModeReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_DARKMODE:
			if (isBoolean(action.payload)) {
				return action.payload;
			} else {
				return !state;
			}
		case types.GET_DARKMODE:
			return state;
		default:
			return state;
	}
};

export default darkModeReducer;
