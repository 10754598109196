import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';
import isNull from 'lodash/isNull';
import replace from 'lodash/replace';

import HelpModal from '../help/videoUploaderHelp';

import StandaloneAlerts from './Alerts';
import CountrySelector from './CountrySelector';
import ContentZoneSelector from './ContentZoneSelector';
import DeckTypeahead from './DeckTypeahead';
import FileInput from './FileInput';
import LanguageSelector from './LanguageSelector';

import {
	handleError,
	printCategory,
	populateFormWithVaultData,
	populatePermissionsAndStatus,
	onChange,
} from './helpers';

class VideoUploader extends React.Component {
	state = {
		loading: false,
		error: false,
		done: false,
		embedded: false,
		isDam: false,
		videoLoading: false,
		showHelp: false,
		mode: 'deploy',
		deckExists: false,
	};

	onChange = (event) => onChange.bind(this)(event);

	onVaultIdChange = async ([value]) => {
		if (!value) return;

		const { downloadVideo, updateForm } = this.props;
		const { mode, embedded } = this.state;

		updateForm({
			vaultId: value.document_number__v,
			video: null,
			jobId: uuidv4(),
			departments: value.department__c,
			conditions: value.condition__c
		});

		try {
			this.setState(
				{
					isDam: value.document_number__v.toLowerCase().includes('dam'),
					isIbr: value.document_number__v.toLowerCase().includes('ibr'),
				},
				async () => {
					const {
						id,
						filename__v: fileName,
					} = await this.populateFormWithVaultData(value.document_number__v);

					await this.populatePermissionsAndStatus(
						'VID',
						value.document_number__v
					);

					console.log('mode: ', mode);
					console.log('embedded: ', embedded);

					if (mode === 'deploy') {
						if (!embedded) {
							this.setState({ videoLoading: true });

							await downloadVideo(id);

							this.setState({ videoLoading: false });

							updateForm(
								'video',
								new File([], fileName, { type: 'video/mp4' })
							);
						}
					} else {
						updateForm('video', null);
					}
				}
			);
		} catch (err) {
			this.setState({ error: handleError(err) });
		}
	};

	populateFormWithVaultData = populateFormWithVaultData.bind(this);

	populatePermissionsAndStatus = populatePermissionsAndStatus.bind(this);

	toggleHelpModal = () => {
		const { showHelp } = this.state;
		this.setState({ showHelp: !showHelp });
	};

	submitForm = async () => {
		const { form, postForm, fetchContent, updateDeckData } = this.props;
		const { mode } = this.state;

		const hasVid = !isNull(form.video);

		const formData = new FormData();
		formData.append('documentId', form.documentId);
		formData.append('mainCategory', form.mainCategory);
		formData.append('secondCategory', form.secondCategory);
		formData.append('keywords', form.keywords);
		formData.append('languageCode', form.languageCode);
		formData.append('contentZone', form.contentZone);
		formData.append('countryCodes', form.countryCodes.toString());
		formData.append('permissions', form.permissions);
		formData.append('qpa', form.qpa);
		formData.append('status', form.status);
		formData.append('vaultId', form.vaultId);
		formData.append('title', replace(form.title, '/', '-'));
		formData.append('embedded', this.state.embedded.toString());
		formData.append('username', this.props.username);
		formData.append('operation', form.operation);
		formData.append('notify', form.notify.toString());
		formData.append('contentType', 'VID');
		formData.append('exposure', form.exposure);
		formData.append('relatedItems', form.relatedItems);
		formData.append('descriptor', form.aceProDescriptor);
		formData.append('wistiaUrl', form.wistiaUrl);
		formData.append('jobId', form.jobId);
		formData.append('departments', form.departments ? form.departments.join(',') : '');
		formData.append('conditions', form.conditions ? form.conditions.join(',') : '');		

		console.log('has vid?', hasVid);

		if (mode === 'deploy' && hasVid) {
			formData.append('video', form.video);
		}

		try {
			if (mode === 'deploy' && hasVid) {
				this.setState({ loading: true });

				await postForm(formData);

				this.setState({
					done: true,
					loading: false,
				});
			} else {
				await updateDeckData(formData);

				this.setState(
					{
						loading: false,
						done: true,
					},
					() => fetchContent('VID')
				);
			}
		} catch (err) {
			this.setState({
				loading: false,
				error: handleError(err),
			});
		}
	};

	printCategory = printCategory.bind(this);

	render() {
		const {
			done,
			deckExists,
			error,
			loading,
			embedded,
			warn,
			videoLoading,
			isDam,
			showHelp,
			mode,
		} = this.state;

		const {
			countries,
			videos,
			fileType,
			locals,
			form: {
				status,
				permissions,
				contentZone,
				countryCodes,
				mainCategory,
				secondCategory,
				language,
				title,
				keywords,
				vaultId,
				video,
				qpa,
				internalMainCategory,
				internalSecondCategory,
				internalThirdCategory,
				externalMainCategory,
				externalSecondCategory,
				externalThirdCategory,
			},
			updateForm,
		} = this.props;

		return (
			<>
				<Container>
					<StandaloneAlerts
						done={done}
						error={error}
						warn={warn}
						fileType={fileType}
						mode={mode}
						setError={(error) => this.setState({ error })}
						setDone={(done) => this.setState({ done })}
						setWarn={(warn) => this.setState({ warn })}
					/>
					<Form encType='multipart/form-data'>
						<Form.Row>
							<h1>Upload Video</h1>
							<p onClick={this.toggleHelpModal}>ⓘ</p>
						</Form.Row>
						<Form.Row>
							{embedded ? (
								<Form.Group as={Col} controlId='vaultId'>
									<Form.Label>Vault ID</Form.Label>
									<Form.Control
										placeholder='Enter Vault ID'
										name='vaultId'
										onChange={this.onChange}
										value={vaultId}
									/>
								</Form.Group>
							) : (
								<DeckTypeahead
									accept='.mp4'
									download={video}
									decks={videos}
									onChange={this.onVaultIdChange}
									labelKey='document_number__v'
									selected={vaultId}
									loading={videoLoading}
									deckExists={deckExists}
									showDownload={embedded}
								/>
							)}
						</Form.Row>
						{mode === 'deploy' && embedded && (
							<Form.Row>
								<Form.Group as={Col} controlId='video'>
									<Form.Label>Video file</Form.Label>
									<FileInput
										name='video'
										id='video'
										accept='video/mp4'
										onChange={this.onChange}
									/>
								</Form.Group>
							</Form.Row>
						)}
						<Form.Row>
							<Form.Group as={Col} controlId='title'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									placeholder='Enter title'
									name='title'
									onChange={this.onChange}
									value={title}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='keywords'>
								<Form.Label>Keywords</Form.Label>
								<Form.Control
									placeholder='Enter comma seperated keywords'
									name='keywords'
									onChange={this.onChange}
									value={keywords}
									disabled={embedded}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='internalCategories'>
								<Form.Label>Internal Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										internalMainCategory,
										internalSecondCategory,
										internalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} controlId='externalCategories'>
								<Form.Label>External Categories</Form.Label>
								<Form.Control
									placeholder={loading ? 'Loading...' : ''}
									name='categories'
									onChange={this.onChange}
									value={this.printCategory(
										externalMainCategory,
										externalSecondCategory,
										externalThirdCategory
									)}
									readOnly
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<LanguageSelector
								locals={locals}
								onChange={this.onChange}
								selected={language}
								disabled={embedded}
							/>
							<ContentZoneSelector
								onChange={this.onChange}
								selected={contentZone}
								disabled={embedded}
							/>
						</Form.Row>
						<Form.Row>
							<CountrySelector
								countries={countries}
								onChange={this.onChange}
								selected={countryCodes}
							/>
						</Form.Row>
						<Form.Group controlId='permissions'>
							<Form.Check
								custom
								name='permissions'
								type='checkbox'
								label='Restricted?'
								checked={permissions === 'R'}
								onChange={({ currentTarget: { checked } }) =>
									updateForm('permissions', checked ? 'R' : 'pub')
								}
								disabled={embedded}
							/>
						</Form.Group>
						<Form.Group controlId='embedded'>
							<Form.Check
								custom
								name='embedded'
								type='checkbox'
								label='Embedded?'
								onChange={() => this.setState({ embedded: !embedded })}
								value={embedded}
							/>
						</Form.Group>
						<Button
							variant='primary'
							onClick={this.submitForm}
							disabled={!video}
							size='lg'
							block
						>
							{loading && (
								<Spinner
									as='span'
									animation='grow'
									role='status'
									aria-hidden='true'
									size='sm'
								/>
							)}
							{mode === 'deploy' ? 'Upload' : 'Update'}
						</Button>
					</Form>
				</Container>
				<HelpModal show={showHelp} handleClose={this.toggleHelpModal} />
			</>
		);
	}
}

export default VideoUploader;
