import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import first from 'lodash/first';
import find from 'lodash/find';
import get from 'lodash/get';

import VideoFileInput from './FileInputWithUpload';

const VideoSelect = ({
	arrayNumber,
	deckIsLoaded,
	hasVideo,
	slide,
	progress,
	upload,
	videos,
	downloadVideo,
	onFileChange,
	onPathChange,
	setVaultVideo,
}) => {
	const videoPath = get(slide, ['video', arrayNumber, 'path'], '');
	const videoEntry =
		upload === 1
			? find(videos, { document_number__v: videoPath })
			: find(videos, (vid) => vid === videoPath);

	const handleUpload = async (val) => {
		await downloadVideo(val.id, val.document_number__v);
		onPathChange(val);
		setVaultVideo(val.document_number__v, val.name__v);
	};

	switch (upload) {
		case 1: {
			return (
				<Form.Group as={Col} controlId='videoPath'>
					<Form.Label>Video path</Form.Label>
					<Typeahead
						id='vault-video-path'
						options={videos || []}
						placeholder='Enter video path'
						name='path'
						selected={[videoEntry?.document_number__v || '']}
						labelKey='document_number__v'
						disabled={!deckIsLoaded}
						onChange={(value) => {
							// need to account for keyboard input
							const val = first(value);
							if (val && val.id) {
								// its selected from the list
								handleUpload(val);
							} else if (typeof val === 'string') {
								// its a keystroke val
								const found = find(videos, { document_number__v: val });
								// we only want to download on a direct match
								if (found) {
									handleUpload(found);
								}
							}
						}}
					/>
				</Form.Group>
			);
		}
		case 2: {
			return (
				<Form.Group as={Col} controlId='videoPath'>
					<Form.Label>Video path</Form.Label>
					<Typeahead
						id='ace-video-path'
						options={videos || []}
						placeholder='Enter video path'
						name='path'
						selected={[videoEntry || '']}
						onChange={(value) => onPathChange(value[0])}
						disabled={!deckIsLoaded}
					/>
				</Form.Group>
			);
		}
		case 3: {
			return (
				<Form.Group as={Col} controlId='video'>
					<Form.Label>Video file</Form.Label>
					<VideoFileInput
						name='video'
						id='desktop-video'
						accept='video/mp4'
						onChange={onFileChange}
						disabled={!deckIsLoaded}
					/>
				</Form.Group>
			);
		}
		default: {
			return null;
		}
	}
};

export default React.memo(VideoSelect);
