import axios from 'axios';
import find from 'lodash/find';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import { handleError, base64ArrayBuffer } from '../uploader/helpers';

import { POWERPOINT, DOCX, DOC, PDF, VIDEO, AUDIO } from './constants';

import type { Query } from '../../typings';

export const validateQuery = (query: Query): boolean => {
	for (const key in query) {
		if (typeof query[key] !== 'string') {
			return false;
		}
	}
	return true;
};

export const isPdf = (docType) =>
	isEqual(docType, PDF) || isEqual(docType, DOCX) || isEqual(docType, DOC);

export const isAudio = (docType) => includes(docType, 'audio');

export const isPowerpoint = (docType) =>
	isEqual(docType, 'application/vnd.ms-powerpoint') ||
	isEqual(
		docType,
		'application/vnd.openxmlformats-officedocument.presentationml.presentation'
	);

export const isVideo = (docType) => includes(docType, 'video');

export { handleError, base64ArrayBuffer };
