import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import clone from 'lodash/clone';
import has from 'lodash/has';
import get from 'lodash/get';

import ChooseLinkModal from '../editor/ChooseLinkModal';
import Carousel from '../editor/Carousel';
import EditorButtonGroup from '../editor/EditorButtonGroup';
import EditorSlideSelector from '../editor/EditorSlideSelector';
import CopyLinksComponent from '../editor/CopyLinksSelector';

import { state, initialize } from '../editor/helpers/links';

class DeckLinkEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...clone(state),
			deck: this.props.vaultId,
			slides: this.props.slides || [],
			backup: this.props.useExperimentalImageMatcher
				? 'expIndexJson'
				: 'sqlIndexJson',
		};

		this.image;

		this.initialize(this);
	}

	initialize = initialize.bind(this);

	componentDidUpdate(prevProps) {
		const { open, slides } = this.props;

		if (slides !== prevProps.slides) {
			this.setState({ slides });
		} else if (open !== prevProps.open && !open) {
			window.onresize = null;
		} else if (open !== prevProps.open && open) {
			window.onresize = this.setScale;
			setTimeout(this.setScale, 100);
		}
	}

	componentDidMount() {
		// remove old listener
		if (typeof window.onresize === 'function') {
			window.onresize = null;
		}

		window.onresize = this.setScale;
	}

	componentWillUnmount() {
		window.onresize = null;
	}

	render() {
		const {
			crop,
			deck,
			arrayNumber,
			selectedSlide,
			slides,
			showChooseLinkModal,
		} = this.state;

		const { images, deckIsLoaded, deckIsInvalid } = this.props;

		const slide = slides[selectedSlide] || {};
		const hasUrls = !!slide.links;

		const noRect =
			!has(slides, [selectedSlide, 'links', arrayNumber, 'url']) &&
			!has(slides, [selectedSlide, 'links', arrayNumber, 'rect']);

		return (
			<>
				<Form className='px-2 py-2'>
					<Form.Row className='d-flex'>
						<Carousel
							arrayNumber={arrayNumber}
							crop={crop}
							deck={deck}
							deckIsLoaded={deckIsLoaded}
							deckIsInvalid={deckIsInvalid}
							hasObject={hasUrls}
							images={images}
							slide={slide}
							slides={slides}
							selectedSlide={selectedSlide}
							changeSelectedObject={this.changeLinkNumber}
							onCropChange={this.onCropChange}
							onImageLoaded={this.onImageLoaded}
							nextSlide={this.nextSlide}
							prevSlide={this.prevSlide}
							property={this.state.property}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Links</Form.Label>
							<Form.Control
								type='text'
								readOnly
								placeholder={get(
									slides,
									[selectedSlide, 'links', arrayNumber, 'url'],
									''
								)}
							/>
						</Form.Group>
						<EditorSlideSelector
							deckIsLoaded={deckIsLoaded}
							deckIsInvalid={deckIsInvalid}
							property={this.state.property}
							changeSlide={this.changeSlide}
							slides={slides}
							selectedSlide={selectedSlide}
						/>
					</Form.Row>
					<CopyLinksComponent 
							slides={slides} 
							sourceSlideId={slide.id} 
							copyLinksToSlides={this.copyLinksToSlides}
							setSlides={(slides) => this.setState({ slides })}
						/>
					<EditorButtonGroup
						property={this.state.property}
						togglePropertyModal={this.toggleLinkModal}
						clear={this.clear}
						deckIsLoaded={deckIsLoaded}
						hasRect={!noRect}
					/>
				</Form>
				<hr />
				<ChooseLinkModal
					hasUrls={hasUrls}
					show={showChooseLinkModal}
					deckIsLoaded={deckIsLoaded}
					url={get(slides, [selectedSlide, 'links', arrayNumber, 'url'], '')}
					restoreOldVals={this.restoreOldVals}
					onChange={this.onChange}
					handleClose={this.toggleLinkModal}
				/>
			</>
		);
	}
}

export default DeckLinkEditor;
