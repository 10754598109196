// @flow

const POWERPOINT: string =
	'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const DOCX: string =
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const DOC: string = 'application/msword';
const PDF: string = 'application/pdf';
const VIDEO: string = 'video/mp4';
const AUDIO: string = 'audio/mp3';
const ZIP: string = 'application/x-zip-compressed';

export { POWERPOINT, DOCX, DOC, PDF, VIDEO, AUDIO, ZIP };
