// @flow

import axios from 'axios';
import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import last from 'lodash/last';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import downloadFile from 'js-file-download';

import type { Component } from 'react';
import type { AxiosPromise } from 'axios';
import type {
	Crop,
	DAMJSON as DAM,
	DECKJSON as DECK,
	Rect,
	Scale,
	Slide,
} from '../../typings';

import { base64ArrayBuffer, handleError } from '../../uploader/helpers';

export type Props = {
	decks: Array<DECK>,
	damJson: DAM,
	deckIsLoaded: boolean,
	deckIsInvalid: boolean,
	isDam: boolean,
	images: Array<string>,
	slides: Array<Slide>,
	open: boolean,
	progress: {
		loaded: number,
		total: number,
	},
	username: string,
	vaultId: string,
	aceVideos: Array<string>,
	vaultVideos: Array<string>,
	changeVideoNumber: ({ currentTarget: { value: string } }) => void,
	changeLinkNumber: ({ currentTarget: { value: string } }) => void,
	downloadVideo: (string) => AxiosPromise<{ data: Blob }>,
	fetchDeck: (string) => AxiosPromise<Array<any>>,
	fetchAceVideos: () => AxiosPromise<Array<string>>,
	fetchVaultVideos: () => AxiosPromise<Array<string>>,
	fetchWorker: (id: string | number) => AxiosPromise<Worker>,
	fetchStagedIndexJson: (string) => AxiosPromise<{}>,
	fetchDeckBackups: (string) => AxiosPromise<{ data: Backups }>,
	searchVault: (string) => AxiosPromise<{ data: { document: Document } }>,
	postForm: (FormData) => AxiosPromise<{ id: string }>,
	toggleAlert: (type: string, error: string) => void,
	toggleError: (error: string) => void,
	toggleLinksEditor: () => void,
	toggleVideoEditor: () => void,
	togglePdfThumbnailEditor: () => void,
	togglePublished: () => void,
	toggleVideoThumbnailEditor: () => void,
	uploadVideo: (FormData) => AxiosPromise<void>,
	uploadPdfThumbnail: () => AxiosPromise<void>,
	uploadVideoThumbnail: () => AxiosPromise<void>,
	updateForm: ({} | string, ?string | ?Array<string> | ?Blob | ?File) => void,
	postForm: (FormData) => AxiosPromise<{ id: string }>,
	publish: (FormData) => AxiosPromise<void>,
	downloadVideo: (id: number) => AxiosPromise<{ data: Blob }>,
};

export type State = {
	arrayNumber: number,
	aspect: number | null,
	backup: 'sqlIndexJson' | 'expIndexJson',
	hasBackups: boolean,
	backupJson: {
		expIndexJson: DECK,
		sqlIndexJson: DECK,
	},
	selectedBackupSlide: number,
	crop: Crop,
	deck: DECK,
	damJson: DAM,
	scale: {
		x: number,
		y: number,
	},
	oldVals: string,
	done: boolean,
	loading: boolean,
	deckIsLoaded: boolean,
	deckIsInvalid: boolean,
	deck: Array<string>,
	error: Error,
	file: File,
	isDam: boolean,
	images: Array<string>,
	slides: Array<Slide>,
	showHelpModal: boolean,
	showUploadModal: boolean,
	showChooseVideoModal: boolean,
	showChooseLinkModal: boolean,
	showOverwriteModal: boolean,
	showResultModal: boolean,
	showJson: boolean,
	selectedSlide: number,
	upload: number,
	worker: Worker,
	property: string,
	selectedLinks: Array<string>
};

export const getVaultId = (opt) => get(opt, 'vaultId') || get(opt, 'v') || opt;

const fetchJson = async function(
	vaultId,
	vaultIntegrated,
	update,
	job
): Promise<DECK> {
	if (this.props.fetchStagedIndexJson && job && !update) {
		return await this.props.fetchStagedIndexJson(job);
	} else if (this.props.fetchStagedIndexJson && job && update) {
		const { data: staged } = await this.props.fetchStagedIndexJson(job);
		const { data } = await this.props.fetchDeck(vaultId);
		let slides = get(data, 'slides', data);
		const stagedSlides = get(staged, 'slides', staged);

		slides = slides
			.map((slide) => (slide = find(stagedSlides, { id: slide.id }) || slide))
			.slice(0, stagedSlides.length);
		return { data: slides };
	} else{
		// pull from sql like normal
		return await this.props.fetchDeck(vaultId);
	}
};

export const getDeckJson = async function(
	this: Component<Props, State>,
	{
		job,
		vaultId,
		vaultIntegrated = false,
		update = false,
	}: {
		job: string,
		vaultId: string,
		vaultIntegrated: boolean,
		update: boolean,
	}
): Promise<void> {
	const { isDam } = this.state;

	try {
		const { data: entry } = await fetchJson.call(
			this,
			vaultId,
			vaultIntegrated,
			update,
			job
		);

		let slides = get(entry, 'slides', entry);

		console.log('initial slides: ', slides);

		await this.preloadImages(vaultId, slides);

		const state = {
			slides,
			arrayNumber: 0,
			selectedSlide: 0,
			jsonString: JSON.stringify(isDam ? entry : slides, null, 3),
			damJson: {},
		};

		if (isDam) {
			state.damJson = entry;
		}

		this.setState(
			{
				...state,
				deckIsInvalid: false,
				deckIsLoaded: true,
				error: false,
			},
			this.setCrop ? this.setCrop : noop
		);

		console.log('is full update: ', update && job);

		console.log('entry: ', entry);
		console.log('slides: ', slides);

		console.log('[DECK IS LOADED]', this.state.deckIsLoaded);

		if (isEmpty(slides)) {
			throw new Error(
				`Recieved 0 slides from API, deck is invalid. Try reuploading ${vaultId}.`
			);
		}
	} catch (err) {
		if (isFunction(this.props.toggleError)) {
			this.props.toggleError(handleError(err));
		}

		this.setState({
			deckIsInvalid: true,
			showResultModal: true,
			error: handleError(err),
		});
	}
};

const getOriginalRect = function(state: State) {
	return get(state.slides, [
		state.selectedSlide,
		state.property,
		state.arrayNumber,
		'rect',
	]);
};

export const setScale = function() {
	const image: Image =
		this.image || last(document.querySelectorAll('.ReactCrop__image'));
	const x: number = image.naturalWidth / image.width;
	const y: number = image.naturalHeight / image.height;

	if (getOriginalRect(this.state)) {
		const rect: Rect = getOriginalRect(this.state);
		const scale: Scale = { x, y };
		this.setState({crop: restoreRect(rect, scale) });
	} else {
		this.setState({ crop: {} });
	}

	this.setState({
		scale: { x, y },
		image: {
			height: image.height,
			width: image.width,
		},
	});
};

export const onImageLoaded = function(image: Image): boolean {
	this.image = image;

	this.setScale();

	// remove old listener
	if (typeof window.onresize === 'function') {
		window.onresize = null;
	}

	window.onresize = this.setScale;

	return false;
};

export function validateSlides(
	property: string,
	slides: Array<Slide>
): boolean {
	slides.forEach((slide: Slide) => {
		if (property === 'video') {
			if (slide[property]) {
				if (Array.isArray(slide[property])) {
					slide[property].forEach((video) => {
						if (!video.path) {
							throw new Error(`${capitalize(property)} path is missing.`);
						} else if (isEmpty(video.path)) {
							throw new Error(`${capitalize(property)} path is empty.`);
						}
					});
				} else {
					if (!slide[property].path) {
						throw new Error(`${capitalize(property)} path is missing.`);
					} else if (isEmpty(slide[property].path)) {
						throw new Error(`${capitalize(property)} path is empty.`);
					}
				}
			}
		} else {
			if (slide[property]) {
				slide[property].forEach((link) => {
					if (!link.url) {
						throw new Error(`${capitalize(property)} url is missing.`);
					} else if (isEmpty(link.url)) {
						throw new Error(`${capitalize(property)} url is empty.`);
					}
				});
			}
		}
	});
	return true;
}

export function checkSlidesValidity(
	property: string,
	slides: Array<Slide>
): boolean {
	try {
		const valid: boolean = validateSlides(property, slides);
		console.log('valid?', !!valid);

		return valid;
	} catch (err) {
		return false;
	}
}

export const submit = async function(
	this: Component<State, Props>,
	property: string,
	noValidate: boolean = false
): Promise<void> {
	try {
		console.log('[NO VALIDATE?]', noValidate);
		const { isDam } = this.state;

		// if we have no property we can't validate
		if (!property) {
			noValidate = true;
		}

		// validate the path variable
		if (isDam) {
			const { damJson } = this.state;
			const slides = get(damJson, 'slides', damJson);
			if (!noValidate) validateSlides(property, slides);
		} else {
			const { slides } = this.state;
			if (!noValidate) validateSlides(property, slides);
		}

		this.setState({ loading: true });

		if (isDam) {
			const { damJson, deck } = this.state;
			console.log('[JSON:] ', damJson);

			await axios.post('/api/update/dam', {
				vaultId: Array.isArray(deck)
					? getVaultId(first(deck))
					: getVaultId(deck),
				damJson,
			});

			this.setState({
				error: false,
				done: true,
				loading: false,
			});
		} else {
			const { deck, slides } = this.state;

			await axios.post('/api/update/deck', {
				deck: Array.isArray(deck) ? getVaultId(first(deck)) : getVaultId(deck),
				slides,
			});

			this.setState({
				error: false,
				done: true,
				loading: false,
			});
		}

		// if no validate is true we're probably in the vault integrated form
		if (isFunction(this.toggleResultModal) && !noValidate) {
			this.toggleResultModal();
		}
	} catch (err) {
		if (!noValidate) {
			if (isFunction(this.props.toggleError)) {
				this.props.toggleError(handleError(err));
			}

			if (isFunction(this.toggleResultModal)) {
				this.toggleResultModal();
			}

			this.setState({
				error: handleError(err),
				loading: false,
			});
		}
	}
};

export const preloadImages = function(
	deck: string,
	slides: Array<Slide>
): Promise<void> {
	return new Promise(async (resolve) => {
		const promises: Array<Promise<string>> = slides.map(
			async (slide: Slide, idx: number) => {
				const { data } = await axios.get(`/api/deck/${deck}/${slide.id}`, {
					responseType: 'arraybuffer',
				});
				this.setState(({ images }) => {
					images[idx] = base64ArrayBuffer(data);
					return images;
				});
			}
		);
		const images: Array<string> = await Promise.race(promises);
		return resolve(images);
	});
};

export const clearAll = function(property: string): void {
	const { slides, selectedSlide } = this.state;

	delete slides[selectedSlide][property];

	this.setState({ slides, crop: {} });
};

export const clear = function(
	this: Component<Props, State>,
	property: string
): void {
	const { arrayNumber, slides, selectedSlide } = this.state;

	if (has(slides, [selectedSlide, property])) {
		slides[selectedSlide][property].splice(arrayNumber, 1);

		if (slides[selectedSlide][property].length === 0) {
			delete slides[selectedSlide][property];
		}

		const newArrayNumber = arrayNumber === 0 ? 0 : arrayNumber - 1;

		this.setState({ slides, arrayNumber: newArrayNumber }, this.setScale);
	} else {
		this.clearAll();
	}
};

export function nextSlide(): void {
	const { slides, selectedSlide } = this.state;
	if (selectedSlide + 1 < slides.length) {
		this.setState(
			{ selectedSlide: selectedSlide + 1 },
			isFunction(this.setCrop) ? this.setCrop : noop
		);
	}
}

export function prevSlide(): void {
	const { selectedSlide } = this.state;
	if (!(selectedSlide - 1 < 0)) {
		this.setState(
			{ selectedSlide: selectedSlide - 1 },
			isFunction(this.setCrop) ? this.setCrop : noop
		);
	}
}

export function nextObject(
	this: Component<Props, State>,
	property: string
): void {
	const { arrayNumber } = this.state;
	const nextSlide: number = arrayNumber + 1;
	const event: { currentTarget: { value: string } } = {
		currentTarget: { value: (nextSlide + 1).toString() },
	};

	if (property === 'video') {
		this.changeVideoNumber(event);
	} else {
		this.changeLinkNumber(event);
	}
}

export function prevObject(
	this: Component<Props, State>,
	property: string
): void {
	const { arrayNumber } = this.state;
	const prevSlide: number = arrayNumber - 1 < 0 ? 0 : arrayNumber - 1;
	const event: { currentTarget: { value: string } } = {
		currentTarget: { value: (prevSlide + 1).toString() },
	};

	if (property === 'video') {
		this.changeVideoNumber(event);
	} else {
		this.changeLinkNumber(event);
	}
}

export function manuallyUpdateJson(
	this: Component<Props, State>,
	code: string
): void {
	const { isDam } = this.state;

	try {
		if (JSON.parse(code)) {
			const json: { [key: string]: any } = JSON.parse(code);

			if (isDam) {
				this.setState({ damJson: json, slides: json.slides });
			} else {
				this.setState({ slides: json });
			}
		}
	} catch (err) {
		// do nothing
	}
}

export const getScale = (): Scale => {
	const image: HTMLElement = document.querySelector('.ReactCrop__image');
	const x: number = image.naturalWidth / image.width;
	const y: number = image.naturalHeight / image.height;

	return { x, y };
};

export function getScaleBound() {
	const image: Image = this.image;
	const x: number = image.naturalWidth / image.width;
	const y: number = image.naturalHeight / image.height;

	return { x, y };
}

// lol
export const getRect = (crop: Crop, scale: Scale = getScale()): Rect => {
	const rect: Rect = {
		x: Math.round(crop.x * scale.x),
		y: Math.round(crop.y * scale.y),
		width: Math.round(crop.width * scale.x),
		height: Math.round(crop.height * scale.y),
	};
	return rect;
};

export const restoreRect = (
	rect: Rect = getDefaultRect(),
	scale: Scale = getScale()
): Crop => {
	const crop: Crop = {
		x: Math.round(rect.x / scale.x),
		y: Math.round(rect.y / scale.y),
		width: Math.round(rect.width / scale.x),
		height: Math.round(rect.height / scale.y),
	};
	return crop;
};

export const getDefaultRect = () => {
    // get the default rect coordinates
    // representing the center of the image

    const image = document.querySelector(".ReactCrop__image");
    const { width: imageWidth , height: imageHeight } = image.getBoundingClientRect();

    const rect = {
        x: imageWidth / 4,
        y: imageHeight / 4,
        width: imageWidth / 2,
        height: imageHeight / 2,
    };

    // Check if any of the rect values are 0
    const hasZeroValue = Object.values(rect).some(value => value === 0);

    // If any value is 0, return the defaultRect. Otherwise, return the calculated rect.
    return hasZeroValue ? defaultRect : rect;
}

export const defaultRect = {
    x: 433,
    y: 270,
    width: 865,
    height: 540,
};

export function downloadJson(): void {
	const { damJson, slides, isDam } = this.state;
	const json = JSON.stringify(isDam ? damJson : slides, null, 3);
	downloadFile(json, 'index.json');
}

export function setSlideJson({ currentTarget: { value } }): void {
	const { slides, selectedSlide, selectedBackupSlide, backupJson } = this.state;

	this.setState({ backup: value });

	set(
		slides,
		[selectedSlide],
		cloneDeep(backupJson[value].slides[selectedBackupSlide])
	);

	// console.log('selected backup: ', value);
	// console.log('backup json:', backupJson[value]);

	this.setState({ slides }, this.setCrop);
}

export function setBackupSlide({ currentTarget: { value } }): void {
	this.setState({ selectedBackupSlide: value }, () => {
		const {
			backup,
			slides,
			selectedSlide,
			selectedBackupSlide,
			backupJson,
		} = this.state;
		const index = parseInt(selectedBackupSlide);

		set(slides, [selectedSlide], cloneDeep(backupJson[backup].slides[index]));

		// console.log('selected slide: ', slides[selectedSlide]);

		this.setState({ slides }, this.setCrop);
	});
}
