import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import { toggleDarkMode } from '../redux/actions';

const DarkModeToggle = ({ ...props }) => {
	const dispatch = useDispatch();
	const darkModeSetting = useSelector((state) => state.darkMode);
	const toggle = () => dispatch(toggleDarkMode());

	const setTheme = (val) => {
		const element = document.body;
		element.classList.add(val ? 'bootstrap-dark' : 'bootstrap');
		element.classList.remove(val ? 'bootstrap' : 'bootstrap-dark');
	};

	useEffect(() => {
		setTheme(darkModeSetting);
	}, [darkModeSetting]);

	return (
		<Button
			{...props}
			variant='secondary'
			onClick={toggle}
			style={{ ...props.style, width: 47.5668 }}
		>
			{darkModeSetting ? '☀' : '☾'}
		</Button>
	);
};

export default DarkModeToggle;
