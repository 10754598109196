import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  listItem: {
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
});

export default styles;
