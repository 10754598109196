import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { useDispatchRequest } from '@redux-requests/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import truncate from 'lodash/truncate';

import { commit, version } from '../../package.json';

import DarkModeToggle from './DarkModeToggle';

import { logout as logoutAction } from '../redux/actions';

const Menu = ({ commitSha, sessionId, username }) => {
	const dispatch = useDispatchRequest();
	const [apiRemove, setApiRemove] = useState(false);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (isEmpty(location.search)) {
			setApiRemove(false);
		} else {
			setApiRemove(true);
		}
	}, [location.search]);

	const logout = () => {
		// if (location.pathname !== '/login') {
		//   navigate('/login');
		// }
		dispatch(logoutAction());
	};

	const login = () => history.push('/login');

	return apiRemove ? (
		<></>
	) : (
		<Navbar expand='lg' className='navbar-themed'>
			<Navbar.Brand as={NavLink} to='/'>
				<img
					src={require('../../public/images/logo.png')}
					width='40'
					height='30'
					className='d-inline-block align-top'
					alt='React logo'
				/>
				{`ACE CMS v${version}`}
			</Navbar.Brand>
			<Navbar.Toggle aria-controls='basic-navbar-nav' />
			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='mr-auto'>
					<Nav.Link as='div'>
						<NavLink to='/uploader'>Uploader</NavLink>
					</Nav.Link>
					<Nav.Link as='div'>
						<NavLink to='/remover'>Remover</NavLink>
					</Nav.Link>
					<Nav.Link as='div'>
						<NavLink to='/video'>Video Editor</NavLink>
					</Nav.Link>
					<Nav.Link as='div'>
						<NavLink to='/links'>Link Editor</NavLink>
					</Nav.Link>
					<Nav.Link as='div'>
						<NavLink to='/categories'>Categories</NavLink>
					</Nav.Link>
					<Nav.Link as='div'>
						<NavLink to='/workers'>Tasks</NavLink>
					</Nav.Link>
				</Nav>
				<Nav>
					<Form inline>
						<ButtonToolbar style={{ paddingTop: '10px' }}>
							<DarkModeToggle style={{ marginRight: '5px' }} />
							{sessionId ? (
								<>
									{(process.env.NODE_ENV === 'development' ||
										window.location.href.includes('test')) && (
										<DropdownButton
											variant='warning'
											style={{ marginRight: '5px' }}
											title='TEST'
										>
											<Dropdown.Item>
												<NavLink to='/vaultTest'>Test Vault Actions</NavLink>
											</Dropdown.Item>
											<Dropdown.Item>
												<NavLink to='/queryTest'>Test Vault Queries</NavLink>
											</Dropdown.Item>
											<Dropdown.Item>
												<NavLink to='/routeTest'>Test ACE Routes</NavLink>
											</Dropdown.Item>
											<Dropdown.Item>
												<NavLink to='/thumbnail'>
													Link Thumbnail Uploader
												</NavLink>
											</Dropdown.Item>
											<Dropdown.Item>
												<NavLink to='/tokenizer'>Tokenizer</NavLink>
											</Dropdown.Item>
											<Dropdown.Item>
												<NavLink to='/logs'>Logs</NavLink>
											</Dropdown.Item>
										</DropdownButton>
									)}
									<Button
										style={{ marginRight: '5px' }}
										variant={
											isEqual(commit, commitSha) &&
											(process.env.NODE_ENV !== 'development' ||
												window.location.href.includes('test'))
												? 'danger'
												: 'primary'
										}
									>
										commit: {truncate(commit, { length: 9 })}
									</Button>
									{!isEmpty(username) && (
										<Button style={{ marginRight: '5px' }}>{username}</Button>
									)}
									<Button onClick={logout}>Logout</Button>
								</>
							) : (
								!window.location.href.includes('login') && (
									<Button onClick={() => login()}>Login</Button>
								)
							)}
						</ButtonToolbar>
					</Form>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Menu;
