import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useDispatchRequest } from '@redux-requests/react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import first from 'lodash/first';
import has from 'lodash/has';
import get from 'lodash/get';

import {
	startTokenizer,
	cleanTokenizerWorkers as cleanWorkersAction,
	fetchTokenizerWorkers,
	restartTokenizerWorker as restartWorkerAction,
	killTokenizerWorker as killWorkerAction,
} from '../redux/actions';

const Tokenizer = () => {
	const dispatch = useDispatchRequest();
	const [optionsOpen, setOptionsOpen] = useState(false);
	const { data: workers, load: loadWorkers } = useQuery({
		type: 'FETCH_TOKENIZER_WORKERS',
		action: fetchTokenizerWorkers,
		autoLoad: true,
	});

	let interval;

	useEffect(() => {
		loadWorkers();
		interval = setInterval(loadWorkers, 10000);
		return () => clearInterval(interval);
	}, []);

	const cleanWorkers = useCallback(() => {
		dispatch(cleanWorkersAction());
	}, [workers]);

	const restartWorker = useCallback(
		(worker) => {
			dispatch(restartWorkerAction(worker));
		},
		[workers]
	);

	const killWorker = useCallback(
		(worker) => {
			dispatch(killWorkerAction(worker));
		},
		[workers]
	);

	const lastWorker = first(workers);

	return (
		<Container>
			<Row>
				<Card style={{ width: '100%', marginBottom: '5px' }}>
					<Card.Header>
						Worker Options{' '}
						<span
							className='float-right'
							style={{ fontWeight: 'bold', cursor: 'pointer' }}
							onClick={() => setOptionsOpen(!optionsOpen)}
						>
							{optionsOpen ? '>' : 'V'}
						</span>
					</Card.Header>
					<Card.Body
						className='justify-content-between'
						style={{ display: optionsOpen ? 'none' : 'flex' }}
					>
						<Button
							variant='primary'
							style={{ flex: '25%' }}
							onClick={() => dispatch(startTokenizer())}
						>
							Run Tokenizer
						</Button>
						<Button
							variant='danger'
							style={{ flex: '25%', marginLeft: '5px' }}
							onClick={cleanWorkers}
						>
							Clear All
						</Button>
					</Card.Body>
				</Card>
			</Row>
			{lastWorker && lastWorker.failedReason && (
				<Row style={{ marginBottom: '10px' }}>
					<Card bg='danger' style={{ width: '100%', color: 'white' }}>
						<Card.Header>Error: Worker {lastWorker.id}</Card.Header>
						<Card.Body>
							<Card.Text>
								{lastWorker.failedReason && `${lastWorker.failedReason}`}
							</Card.Text>
						</Card.Body>
					</Card>
				</Row>
			)}
			{workers &&
				workers.map((worker, idx) => (
					<Row key={idx}>
						<Card style={{ width: '100%', marginBottom: '5px' }}>
							<Card.Body>
								<p>
									Worker {worker.id}:{' '}
									{get(worker, 'data.formData.vaultId', `can't fetch Vault ID`)}
								</p>
								{has(worker, 'failedReason') ? (
									<ProgressBar
										variant='danger'
										now={worker.progress}
										label={`${Math.round(worker.progress)}%`}
									/>
								) : (
									<ProgressBar
										animated={!worker.finishedOn}
										now={worker.progress}
										label={`${Math.round(worker.progress)}%`}
									/>
								)}
							</Card.Body>
							<Card.Footer>
								<div className='float-left'>
									<p style={{ marginTop: '5px', marginBottom: '0px' }}>
										Started:{' '}
										{moment(worker.opts.timestamp).format(
											'MMMM Do YYYY, h:mm:ss a'
										)}
										{worker.finishedOn &&
											`, Finished: ${moment(worker.finishedOn).format(
												'MMMM Do YYYY, h:mm:ss a'
											)}`}
										{worker.failedReason &&
											`, Failed Reason: ${worker.failedReason}`}
									</p>
								</div>
								<ButtonToolbar className='float-right'>
									<Button
										onClick={() => restartWorker(worker)}
										disabled={worker.progress === 100}
									>
										Restart
									</Button>
									<Button
										variant='secondary'
										onClick={() => killWorker(worker)}
										style={{ marginLeft: '5px' }}
									>
										Clear
									</Button>
								</ButtonToolbar>
							</Card.Footer>
						</Card>
					</Row>
				))}
		</Container>
	);
};

export default Tokenizer;
